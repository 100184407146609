import React from "react";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { CSVLink } from "react-csv";
import Loading from "../../subComponents/Loading";

const apiUrl = process.env.REACT_APP_API_URL;

const Customers = () => {
  const [filteredData, setFilteredData] = useState([]);

  const [item, setItem] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  const columns = [
    {
      name: (
        <h1 className="text-lg text-globalBlack font-semibold">First Name</h1>
      ),
      selector: (row) => row.first_name,
    },
    {
      name: (
        <h1 className="text-lg text-globalBlack font-semibold">Last Name</h1>
      ),
      selector: (row) => row.last_name,
    },
    {
      name: <h1 className="text-lg text-globalBlack font-semibold">Mail ID</h1>,
      selector: (row) => row.email,
    },
    {
      name: (
        <h1 className="text-lg text-globalBlack font-semibold">Phone Number</h1>
      ),
      selector: (row) => row.phoneno,
    },
    // {
    //   name: <h1 className="text-lg text-gray-500">Actions</h1>,
    //   cell: (row) => (
    //     <>
    //       <div className="flex flex-row items-center gap-2">
    //         <FcViewDetails
    //           className="w-8 h-6 text-blue-500 cursor-pointer"
    // onClick={() => navigate("/homepage/usersDetails/" + row.id)}
    //           onClick={() => navigate("/homepage/user/userdetailpage/" + row.id)}
    //         />
    //         {/* <button
    //           className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
    //           onClick={() => openModal(row.id)}
    //         >
    //           Open Profile
    //         </button> */}
    //         {/* <MdDelete
    //           onClick={() => handleDelete(row.id)}
    //           className="w-8 h-6 text-red-500 cursor-pointer"
    //         />
    //         <Link to={`/homepage/usersformedit/${row.id}`}>
    //           <FiEdit className="w-8 h-5 text-blue-500 cursor-pointer" />
    //         </Link> */}
    //         {/* <FaCheck
    //           className="w-8 h-5 text-green-500 cursor-pointer"

    //         />
    //         <TiCancel
    //           className="w-8 h-7 text-red-400 cursor-pointer"

    //         /> */}
    //       </div>
    //     </>
    //   ),
    // },
  ];

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  const handleDelete = async (id) => {
    confirmAlert({
      title: "Are you sure",
      message: "You want to delete this data ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await axios.delete(`${apiUrl}/users/${id}`, config).then((res) => {
              //  console.log(res.status);
              if (res.status === 200) {
                setFilteredData((prevData) =>
                  prevData.filter((item) => item.id !== id)
                );
                toast.success(res.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "fixed top-14 right-1",
                });
              } else {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "fixed top-14 right-1",
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            toast.warn("cancelled", {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          },
        },
      ],
    });
  };

  const [pending, setPending] = useState(true);

  useEffect(() => {
    axios
      .get(`${apiUrl}/users`, config)
      .then((res) => {
        if (res.data !== undefined) {
          if (res.status === 200) {
            console.log(res.data.data);
            setItem(res.data.data);
            setPending(false);
          }
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, []);

  useEffect(() => {
    const result =
      item &&
      item.filter((value) => {
        return value.first_name.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [item, searchTerm]);

  const customStyles = {
    header: {
      style: {
        textAlign: "center", // Center header text
      },
    },

    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "30px", // override the cell padding for head cells
        paddingRight: "30px",
        backgroundColor: "#FBF7ED",
        color: "#1F2937",
        fontWeight: "400",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#fff9f3",
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        paddingLeft: "40px",
        paddingRight: "40px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "15px",
        color: "#1F2937",
        borderRight: "1px solid #ddd",
      },
    },
  };

  const handleCsv = () => {
    if (item !== undefined) {
      const csvData = filteredData.map((item) => ({
        "First Name": item.first_name,
        "Last Name": item.last_name,
        "Mail ID": item.email,
        "Phone Number": item.phoneno,
      }));
      return csvData;
    }
    return [];
  };

  const handleRowClick = (row) => {
    navigate(`/homepage/user/userdetailpage/${row.id}`);
  };

  return (
    <>
      {pending ? (
        <>
          <Loading />
        </>
      ) : (
        <>
          <div className="flex flex-row justify-between w-full h-16 items-start gap-1">
            <div>
              <h4 className="text-2xl md:text-3xl font-bold text-globalBlack">
                Customers
              </h4>
            </div>

            {/* <div>
            <Link to="/homepage/usersform">
              <button
                className="py-1.5 px-4 md:py-2 md:px-7 text-md md:text-lg bg-primary text-white shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 rounded-md md:rounded-xl font-semibold flex flex-row 
          items-center gap-1"
              >
                <FaPlus  />
                Add
              </button>
            </Link>
          </div> */}
          </div>
          <div className="w-full h-12 flex flex-wrap justify-between gap-8 items-center">
            <input
              placeholder="Search..."
              type="text"
              required
              className="p-2 text-lg border border-gray-400 
        rounded focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
          dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
              onChange={(event) => setSearchTerm(event.target.value)}
              value={searchTerm}
            />
            <div>
              <CSVLink
                data={handleCsv()}
                filename="users.csv"
                className="py-1.5 px-4 sm:py-2 sm:px-7 bg-primary text-lg text-white shadow shadow-primary hover:shadow-primary hover:bg-secondary ease-in-out duration-300 text-normal font-semibold
      rounded"
              >
                Export
              </CSVLink>
            </div>
          </div>
          <div className="mt-10">
            <DataTable
              fixedHeader={true}
              columns={columns}
              data={filteredData}
              pagination
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              fixedHeaderScrollHeight="250px"
              onRowClicked={handleRowClick}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Customers;
