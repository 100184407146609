import React from "react";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useContext } from "react";
import { AppContext } from "../../components/AppContext";
const apiUrl = process.env.REACT_APP_API_URL;

const ShowFeeDetails = () => {
  const { getMemberId } = useContext(AppContext);

  const [filteredData, setFilteredData] = useState([]);

  const [item, setItem] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const columns = [
    {
      name: <h1 className="text-lg text-globalBlack ">Name</h1>,
      selector: (row) => row.name,
    },
    {
      name: <h1 className="text-lg text-globalBlack ">Email-Address</h1>,
      selector: (row) => row.email,
    },
    {
      name: <h1 className="text-lg text-globalBlack">Fee_Earned</h1>,
      selector: (row) => "£" + row.fee_earned,
    },
    // {
    //   name: <h1 className="text-lg text-gray-500">Actions</h1>,
    //   cell: (row) => (
    //     <>
    //       <div className="flex flex-row items-center gap-2">
    //         <MdDelete
    //           onClick={() => handleDelete(row.id)}
    //           className="w-8 h-6 text-red-500 cursor-pointer"
    //         />
    //         <Link to={`/homepage/rolesformedit/${row.id}`}>
    //           <FiEdit className="w-8 h-5 text-blue-500 cursor-pointer" />
    //         </Link>
    //         {/* <FaCheck className="w-8 h-5 text-green-500 cursor-pointer" />
    //          <TiCancel className="w-8 h-7 text-red-400 cursor-pointer" /> */}
    //       </div>
    //     </>
    //   ),
    // },
  ];

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  const handleDelete = async (id) => {
    confirmAlert({
      title: "Are you sure",
      message: "You want to delete this data ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await axios.delete(`${apiUrl}/roles/${id}`, config).then((res) => {
              //  console.log(res.data);
              if (res.status === 200) {
                setFilteredData((prevData) =>
                  prevData.filter((item) => item.id !== id)
                );
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "fixed top-14 right-1",
                });
              } else {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "fixed top-14 right-1",
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            toast.warn("cancelled", {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          },
        },
      ],
    });
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/doctors/${getMemberId}`, config)
      .then((res) => {
        console.log(res.data.data);
        setItem(res.data.data);
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, [getMemberId]);

  //   useEffect(() => {
  //     const result =
  //       item &&
  //       item.filter((value) => {
  //         return value.name.toLowerCase().match(searchTerm.toLowerCase());
  //       });
  //     setFilteredData(result);
  //   }, [item, searchTerm]);

  const customStyles = {
    rows: {
      style: {
        minHeight: "48px", // override the row height
        minWidth: "500px",
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
        backgroundColor: "#FBF7ED",
        color: "#6c737f",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        fontSize: "16px",
        color: "#364353",
      },
    },
  };

  return (
    <>
      <div className=" ">
        <div className="flex flex-row justify-between w-full h-16 items-start gap-1">
          <div>
            <h4 className="text-2xl md:text-3xl font-bold text-globalBlack">
              Fee Details
            </h4>
          </div>
          {/* <div>
            <Link to="/homepage/rolesform">
              <button
                className="py-1.5 px-4 md:py-2 md:px-7 text-md md:text-lg bg-primary text-white shadow-sm shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 rounded-md md:rounded-xl font-semibold flex flex-row 
          items-center gap-1"
              >
                <FaPlus />
                Add
              </button>
            </Link>
          </div> */}
        </div>
        <div className="w-full h-12 flex flex-row items-center my-6 md:my-4 ">
          {/* <input
            placeholder="search..."
            type="text"
            required
            className="p-2 text-lg border border-gray-400 
        rounded-lg focus:border-primary focus:bg-white focus:text-blue-500 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
          dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          /> */}
        </div>
        <div className="mt-6">
          <DataTable
            columns={columns}
            data={item}
            fixedHeader
            pagination
            bordered
            customStyles={customStyles}
          />
          
        </div>
      </div>
    </>
  );
};

export default ShowFeeDetails;
