import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import { toast} from "react-toastify";
import { useParams, useNavigate } from "react-router";
import he from 'he';

const apiUrl = process.env.REACT_APP_API_URL;

const EmailTemplateForm = () => {
  const initialstate = {
    name: "",
    subject:"",
    form_id:"",
    body:""
  };


 

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  const [form, setForm] = useState(initialstate);

  // console.log(form)

   console.log(form.body);

  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!editMode) {
      await axios
        .post(
          `${apiUrl}/emailtemplate`,
          {
            name: form.name,
            subject:form.subject,
            form_id:form.form_id,
            body:form.body,
          },
          config
        )
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          navigate("/homepage/emailtemplate")
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    } else {
      await axios
        .put(
          `${apiUrl}/emailtemplate/${id}`,
          {
            name: form.name,
            subject: form.subject,
            form_id: form.form_id,
            body: he.escape(form.body),
          },
          config
        )
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
            // navigate("/roles");
            navigate("/homepage/emailtemplate")
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    }
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setForm({ ...initialstate });
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/emailtemplate/${id}`, config);
    console.log(formData.data.data);
    if (formData.status === 200) {
      formData.data.data[0].body = he.decode(formData.data.data[0].body);
      setForm({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  return (
    <div>
      <div className="flex flex-rows justify-center items-center w-full py-8 border-b-2 border-gray-300">
        <h2 className="text-xl md:text-2xl  font-semibold text-globalBlack w-full">
          Form
        </h2>
        <Link to="/homepage/emailtemplate">
          <FaTimes className="w-12 h-6 cursor-pointer text-gray-500" />
        </Link>
      </div>
      {/* <div className="w-full h-[115px] bg-gray-400"></div> */}
      <form onSubmit={handleSubmit}>
        <div className="w-full h-full grid grid-cols-1  gap-10">
          <div className="grid grid-cols-1 md:grid-cols-3 pt-6">
            <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  Name
                </label>
              </div>
              <div className="grid grid-cols-1">
                <input
                  type="text"
                  required
                  name="name"
                  value={form.name}
                  onChange={(e) => setForm({ ...form, name: e.target.value })}
                  placeholder="Vicky"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                />
              </div>
            </div>
            <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  Subject
                </label>
              </div>
              <div className="grid grid-cols-1">
                <input
                  type="text"
                  required
                  name="subject"
                  value={form.subject}
                  onChange={(e) =>
                    setForm({ ...form, subject: e.target.value })
                  }
                  placeholder="Subject"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                />
              </div>
            </div>
            <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  Form_id
                </label>
              </div>
              <div className="grid grid-cols-1">
                <input
                  type="text"
                  required
                  name="form_id"
                  value={form.form_id}
                  onChange={(e) =>
                    setForm({ ...form, form_id: e.target.value })
                  }
                  placeholder="1"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                />
              </div>
            </div>
          </div>

          <div className="h-fit grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
            <div>
              <label className="text-lg font-medium text-globalBlack">Body</label>
            </div>
            <textarea
              value={form.body}
              onChange={(e) => setForm({ ...form, body: e.target.value })}
              className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
            />
            {/* <Editor
              apiKey="wdkecpbmr0iy7t2tatwvjpgi85s31if69utt174bhbvqv1ye"
              initialValue={form.body}
              init={{
                branding: false,
                height: 300,
                menubar: true,
                plugins:
                  " preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap  pagebreak nonbreaking anchor insertdatetime advlist lists wordcount",
                toolbar:
                  "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                image_advtab: true,
              }}
              onChange={(e) =>
                setForm({ ...form, body: e.target.getContent() })
              }
              // onEditorChange={(newContent, editor) => {
              //   // Update the form.body state with the new content
              //   setForm({ ...form, body: newContent });
              // }}
            /> */}
          </div>
          <div className="flex flex-row justify-center items-center my-16 gap-6 ">
            <button
              className="py-2 px-7 bg-primary text-white shadow shadow-primary hover:bg-secondary ease-in-out duration-300 text-normal font-semibold
      rounded-md "
              type="submit"
            >
              {editMode ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    
      
    </div>
  );
};

export default EmailTemplateForm;
