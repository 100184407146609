import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast} from "react-toastify";
import { useParams, useNavigate } from "react-router";

const apiUrl = process.env.REACT_APP_API_URL;

const ProductForm1 = ({ page, setPage}) => {
  const initialstate = {
    question1: "",
    answer1: "",
    question2: "",
    answer2: "",
    question3: "",
    answer3: "",
    question4: "",
    answer4: "",
    question5: "",
    answer5: "",
  };

   const config = {
     headers: {
       authorization: `Bearer ${localStorage.getItem("token_id")}`,
     },
   };

  const FormTiles = ["Product Form", "FAQ Form"];

  const [form, setForm] = useState(initialstate);

   const [editMode, setEditMode] = useState(false);

   const navigate = useNavigate();

   const handleSubmit = async (event) => {
     event.preventDefault();

     if (!editMode) {
       await axios
         .post(`${apiUrl}/products/faq`, {
           question1: form.question1,
           answer1: form.answer1,
           question2: form.question2,
           answer2: form.answer2,
           question3: form.question3,
           answer3: form.answer3,
           question4: form.question4,
           answer4: form.answer4,
           question5: form.question5,
           answer5: form.answer5,
           created_by: 1,
         },config)
         .then((res) => {
           console.log(res.data);
           if (res.status === 200) {
             toast.success(res.data.message, {
               position: toast.POSITION.TOP_RIGHT,
               className: "fixed top-14 right-1",
             });
             navigate("/homepage/products");
           } else {
             toast.error(res.data.message, {
               position: toast.POSITION.TOP_RIGHT,
               className: "fixed top-14 right-1",
             });
           }
         });
     } else {
       await axios
         .put(`${apiUrl}/products/faq/${id}`, {
           question1: form.question1,
           answer1: form.answer1,
           question2: form.question2,
           answer2: form.answer2,
           question3: form.question3,
           answer3: form.answer3,
           question4: form.question4,
           answer4: form.answer4,
           question5: form.question5,
           answer5: form.answer5,
           created_by: 1,
         },config)
         .then((res) => {
           console.log(res.data);
           if (res.status === 200) {
             toast.success(res.data.message, {
               position: toast.POSITION.TOP_RIGHT,
               className: "fixed top-14 right-1",
             });
             navigate("/homepage/products");
           } else {
             toast.error(res.data.message, {
               position: toast.POSITION.TOP_RIGHT,
               className: "fixed top-14 right-1",
             });
           }
         });
     }
   };

   const { id } = useParams();

   useEffect(() => {
     if (id) {
       setEditMode(true);
       getFormData(id);
     } else {
       setEditMode(false);
       setForm({ ...initialstate });
     }
   }, [id]);

   const getFormData = async (id) => {
     const formData = await axios.get(`${apiUrl}/products/faq/${id}`,config);
     console.log(formData.data.data.fullname);
     if (formData.status === 200) {
       setForm({ ...formData.data.data[0] });
     } else {
       console.log("Something went wrong !!");
     }
   };


  return (
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
            <div>
              <label className="text-lg font-medium text-globalBlack">
                Question 1
              </label>
            </div>
            <div className="grid grid-cols-1">
              <input
                type="text"
                required
                value={form.question1}
                onChange={(e) =>
                  setForm({ ...form, question1: e.target.value })
                }
                placeholder="Enter your question..."
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div>
          <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
            <div>
              <label className="text-lg font-medium text-globalBlack">
                Answer 1
              </label>
            </div>
            <textarea
              required
              value={form.answer1}
              onChange={(e) => setForm({ ...form, answer1: e.target.value })}
              placeholder="Enter your Answer..."
              type="text"
              className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
            />
          </div>
          <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
            <div>
              <label className="text-lg font-medium text-globalBlack">
                Question 2
              </label>
            </div>
            <div className="grid grid-cols-1">
              <input
                type="text"
                required
                value={form.question2}
                onChange={(e) =>
                  setForm({ ...form, question2: e.target.value })
                }
                placeholder="Enter your question..."
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div>
          <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
            <div>
              <label className="text-lg font-medium text-globalBlack">
                Answer 2
              </label>
            </div>
            <textarea
              required
              value={form.answer2}
              onChange={(e) => setForm({ ...form, answer2: e.target.value })}
              placeholder="Enter your Answer..."
              type="text"
              className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
            />
          </div>

          <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
            <div>
              <label className="text-lg font-medium text-globalBlack">
                Question 3
              </label>
            </div>
            <div className="grid grid-cols-1">
              <input
                type="text"
                required
                value={form.question3}
                onChange={(e) =>
                  setForm({ ...form, question3: e.target.value })
                }
                placeholder="Enter your question..."
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div>
          <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
            <div>
              <label className="text-lg font-medium text-globalBlack">
                Answer 3
              </label>
            </div>
            <textarea
              required
              value={form.answer3}
              onChange={(e) => setForm({ ...form, answer3: e.target.value })}
              placeholder="Enter your Answer..."
              type="text"
              className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
            />
          </div>
          <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
            <div>
              <label className="text-lg font-medium text-globalBlack">
                Question 4
              </label>
            </div>
            <div className="grid grid-cols-1">
              <input
                type="text"
                required
                value={form.question4}
                onChange={(e) =>
                  setForm({ ...form, question4: e.target.value })
                }
                placeholder="Enter your question..."
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div>
          <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
            <div>
              <label className="text-lg font-medium text-globalBlack">
                Answer 4
              </label>
            </div>
            <textarea
              required
              value={form.answer4}
              onChange={(e) => setForm({ ...form, answer4: e.target.value })}
              placeholder="Enter your Answer..."
              type="text"
              className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
            />
          </div>
          <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
            <div>
              <label className="text-lg font-medium text-globalBlack">
                Question 5
              </label>
            </div>
            <div className="grid grid-cols-1">
              <input
                type="text"
                required
                value={form.question5}
                onChange={(e) =>
                  setForm({ ...form, question5: e.target.value })
                }
                placeholder="Enter your question..."
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div>
          <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
            <div>
              <label className="text-lg font-medium text-globalBlack">
                Answer 5
              </label>
            </div>
            <textarea
              required
              value={form.answer5}
              onChange={(e) => setForm({ ...form, answer5: e.target.value })}
              placeholder="Enter your Answer..."
              type="text"
              className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
            />
          </div>
        </div>

        <div className="flex flex-row justify-center items-center mt-16 mb-10 gap-6 ">
          <button
            className="py-2 px-7 bg-primary text-gray-200 text-normal font-semibold
        rounded-md hover:bg-secondary"
            disabled={page === 0}
            onClick={() => {
              setPage((currPage) => currPage - 1);
            }}
          >
            Prev
          </button>
          <button
            className="py-2 px-7 bg-primary text-white shadow shadow-primary hover:bg-secondary ease-in-out duration-300 text-normal font-semibold
      rounded-md"
            type="submit"
          >
            {editMode ? "Update" : "Submit"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default ProductForm1;
