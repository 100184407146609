import React from 'react'

const Loading = () => {
  return (
    <div className="flex absolute right-0 top-80 w-full  z-0  justify-center items-center ">
      <span className="loading loading-spinner loading-lg text-secondary"></span>
    </div>
  );
}

export default Loading