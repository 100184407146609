import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import { toast} from "react-toastify";
import { useParams, useNavigate } from "react-router";

const apiUrl = process.env.REACT_APP_API_URL;

const CustomersForm = () => {
  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  const initialstate = {
    first_name: "",
    last_name: "",
    email: "",
    phoneno: "",
    password: "",
  };

  const [form, setForm] = useState(initialstate);

  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!editMode) {
      await axios
        .post(`${apiUrl}/users`, {
          first_name: form.first_name,
          last_name: form.last_name,
          email: form.email,
          phoneno: form.phoneno,
          password: form.password,
          created_by: 1,
        })
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
            navigate("/user");
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    } else {
      await axios
        .put(
          `${apiUrl}/users/${id}`,
          {
            first_name: form.first_name,
            last_name: form.last_name,
            email: form.email,
            phoneno: form.phoneno,
            password: form.password,
            created_by: 1,
          },
          config
        )
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
            navigate("/user");
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    }
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setForm({ ...initialstate });
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/users/${id}`, config);
    console.log(formData.data.data.fullname);
    if (formData.status === 200) {
      setForm({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  return (
    <div
      className="flex flex-col w-full lg:w-4/5 h-screen scrollbar-thin scrollbar-thumb-gray-400  scrollbar-black1 overflow-y-scroll  
      fixed  md:fixed left-1/5 right-0 top-16  px-6 gap-2 bg-gray-100 shadow-md rounded-md "
    >
      <div className="flex flex-rows justify-center items-center w-full  h-12 my-8 ">
        <h2 className="text-xl md:text-2xl font-semibold text-gray-500 w-full">
          Form
        </h2>
        <Link to="/homepage/user">
          <FaTimes className="w-12 h-6 cursor-pointer text-gray-500" />
        </Link>
      </div>
      <div className="w-full h-px bg-gray-400"></div>
      <form onSubmit={handleSubmit}>
        <div className="w-full h-full grid grid-cols-1  gap-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
              <div>
                <label className="text-lg font-medium text-gray-500">
                  First Name
                </label>
              </div>
              <div className="grid grid-cols-1">
                <input
                  type="text"
                  required
                  name="Name"
                  value={form.first_name}
                  onChange={(e) =>
                    setForm({ ...form, first_name: e.target.value })
                  }
                  placeholder="Full Name"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                />
              </div>
            </div>
            <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
              <div>
                <label className="text-lg font-medium text-gray-500">
                  Last Name
                </label>
              </div>
              <div className="grid grid-cols-1">
                <input
                  type="text"
                  required
                  name="Name"
                  value={form.last_name}
                  onChange={(e) =>
                    setForm({ ...form, last_name: e.target.value })
                  }
                  placeholder="Full Name"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                />
              </div>
            </div>
            <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
              <div>
                <label className="text-lg font-medium text-gray-500">
                  Email Address
                </label>
              </div>
              <input
                required
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
                placeholder="Email"
                type="email"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        "
              />
            </div>
            <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4 ">
              <div>
                <label className="text-lg font-medium text-gray-500">
                  Password
                </label>
              </div>

              <input
                type="password"
                required
                value={form.password}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
                placeholder="Password"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
          text-md text-gray-400 focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
          dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
              />
            </div>
            <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
              <div>
                <label className="text-lg font-medium text-gray-500">
                  Phone Number
                </label>
              </div>
              <input
                required
                value={form.phoneno}
                onChange={(e) => setForm({ ...form, phoneno: e.target.value })}
                placeholder="Phone no"
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div>

          <div className="flex flex-row justify-center items-center my-24 gap-6 ">
            <button
              className="py-2 px-7 bg-primary text-white shadow shadow-primary hover:bg-secondary ease-in-out duration-300  text-normal font-semibold
      rounded-md"
              type="submit"
            >
              {editMode ? "Update" : "Submit"}
            </button>
          </div>
        </div>
       
      </form>
    </div>
  );
};

export default CustomersForm;
