import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { FaFilePrescription } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const apiUrl = process.env.REACT_APP_API_URL;

const PrescriberNotes = () => {
  const { id, assessmentid } = useParams();

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  //for prescrier notes

  const [getPrecriberNotes, setGetPrecriberNotes] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/prescriberNotes/${id}`, config)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data !== undefined) {
          setGetPrecriberNotes(res.data.data);
          console.log("prescriber", res.data.data);
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });

    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <VerticalTimeline>
        {getPrecriberNotes.length === 0 ? (
          <p>No Notes found.</p>
        ) : (
          getPrecriberNotes.map((event) => (
            <VerticalTimelineElement
              key={event.id}
              contentStyle={{
                background: "white",
                color: "black",
                borderTop: "4px solid #2196f3",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  #fff",
              }}
              className="vertical-timeline-element--work"
              date={event.notes_date}
              iconStyle={{ background: "#2196f3", color: "#fff" }}
              icon={<FaFilePrescription />}
            >
              <p>{event.notes}</p>
            </VerticalTimelineElement>
          ))
        )}
      </VerticalTimeline>
    </>
  );
};

export default PrescriberNotes;
