import { Outlet, Navigate } from "react-router-dom";

const PrivateRoutes = () => {

   const token = localStorage.getItem("auth")
   
  let auth = { token: token };
  return auth.token ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRoutes;
