import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { MdDelete } from "react-icons/md";

const apiUrl = process.env.REACT_APP_API_URL;

const ContactUs = () => {
  const [filteredData, setFilteredData] = useState([]);

  const [item, setItem] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  const handleDelete = async (id) => {
    confirmAlert({
      title: "Are you sure",
      message: "You want to delete this data ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await axios
              .delete(`${apiUrl}/contactusdetail/${id}`, config)
              .then((res) => {
                //  console.log(res.data);
                if (res.status === 200) {
                  setFilteredData((prevData) =>
                    prevData.filter((item) => item.id !== id)
                  );
                  toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: "fixed top-14 right-1",
                  });
                } else {
                  toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: "fixed top-14 right-1",
                  });
                }
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            toast.warn("cancelled", {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          },
        },
      ],
    });
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/contactusdetail`, config)
      .then((res) => {
        console.log(res.data === undefined);
        if (res.data.data !== undefined) {
          setItem(res.data.data);
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, []);

  useEffect(() => {
    const result =
      item &&
      item.filter((value) => {
        return value.name.toString().includes(searchTerm.toString());
      });
    setFilteredData(result);
  }, [item, searchTerm]);

  return (
    <>
      <div>
        <div className="flex flex-row justify-between w-full h-16 items-start gap-1">
          <div>
            <h4 className="text-2xl md:text-3xl font-bold text-globalBlack">
              Contact Details
            </h4>
          </div>
        </div>
        <div className="w-full h-12 flex flex-row items-center my-6 md:my-4 justify-between ">
          <input
            placeholder="search..."
            type="text"
            required
            className="p-2 text-lg border border-gray-400 
          rounded focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
            dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          />
        </div>

        <div className="my-6 p-5 w-full h-[320px] overflow-y-scroll flex justify-center items-center border shadow rounded bg-stone-50">
          {item.length >= 1 ? (
            <>
              {" "}
              {filteredData?.map((d) => (
                <div
                  className="w-full h-fit flex flex-row justify-center items-center rounded border p-4 bg-white"
                  key={d.id}
                >
                  <div className="w-1/3 h-full flex flex-col gap-8 font-semibold text-md">
                    <p>Name: {d.name}</p>
                    <p>Email Address: {d.email_address}</p>
                    <p>Phone Number: {d.phone_number}r</p>
                  </div>
                  <div className="w-2/3 h-fit flex flex-col gap-5 p-3 rounded shadow bg-fourth">
                    <div className="w-full flex justify-between items-center">
                      <p className="text-2xl text-globalBlack font-semibold">
                        Query
                      </p>
                      <MdDelete
                        onClick={() => handleDelete(d.id)}
                        className="w-6 h-6 text-red-500 cursor-pointer"
                      />
                    </div>

                    <span className="">{d.query}</span>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <h1 className="text-xl text-globalBlack font-semibold">
              There is no records to display
            </h1>
          )}
        </div>
      </div>
    </>
  );
};

export default ContactUs;
