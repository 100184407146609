import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import { toast} from "react-toastify";
import { useParams, useNavigate } from "react-router";

const apiUrl = process.env.REACT_APP_API_URL;

const ReferralForm = () => {
  const initialstate = {
    username: "",
    referred_username: "",
    referral_code:""
  };
  

  const [form, setForm] = useState(initialstate);

  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!editMode) {
      await axios
        .post(
          `${apiUrl}/referral`,
          {
            username: form.username,
            referred_username: form.referred_username,
            referral_code:form.referral_code,
            created_by: 1,
          },
          config
        )
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
            // navigate("/homepage");
            navigate("/homepage/referral")
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    } else {
      await axios
        .put(
          `${apiUrl}/referral/${id}`,
          {
            username: form.username,
            referred_username: form.referred_username,
            referral_code: form.referral_code,
            created_by: 1,
          },
          config
        )
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
             navigate("/homepage/referral");
          
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    }
 
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setForm({ ...initialstate });
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/referral/${id}`, config);
    console.log(formData.data.data);
    if (formData.status === 200) {
      setForm({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  return (
    <div
      
    >
      <div className="flex flex-rows justify-center items-center w-full py-8 border-b-2 border-gray-300">
        <h2 className="text-xl md:text-2xl  font-semibold text-globalBlack w-full">
          Form
        </h2>
        <Link to="/homepage/referral">
          <FaTimes className="w-12 h-6 cursor-pointer text-gray-500" />
        </Link>
      </div>
     
      <form onSubmit={handleSubmit}>
        <div className="w-full h-full grid grid-cols-1 pt-8 gap-16">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  User Name
                </label>
              </div>
              <div className="grid grid-cols-1">
                <input
                  type="text"
                  required
                  name="username"
                  value={form.username}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      username: e.target.value,
                    })
                  }
                  placeholder="Suhail"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                />
              </div>
            </div>
            <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  Referred Username
                </label>
              </div>
              <div className="grid grid-cols-1">
                <input
                  type="text"
                  required
                  name="referred_username"
                  value={form.referred_username}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      referred_username: e.target.value,
                    })
                  }
                  placeholder="John"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                />
              </div>
            </div>
            <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  Referral Code
                </label>
              </div>
              <input
                required
                value={form.referral_code}
                onChange={(e) =>
                  setForm({ ...form, referral_code: e.target.value })
                }
                placeholder="3KDCF12"
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        "
              />
            </div>
          </div>

          <div className="flex flex-row justify-center items-center gap-6 mb-10">
            {/* <Link to="/homepage/referral"> */}
            <button
              className="py-2 px-7 bg-primary text-white shadow shadow-primary hover:bg-secondary ease-in-out duration-300 text-normal font-semibold
      rounded-md "
              type="submit"
            >
              {editMode ? "Update" : "Submit"}
            </button>
            {/* </Link> */}
          </div>
        </div>
        
      </form>
    </div>
  );
};

export default ReferralForm;
