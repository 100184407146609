import React, { useEffect, useState } from "react";
import {
  Font
} from "@react-pdf/renderer";
import { Link, useParams } from "react-router-dom";
import { toast} from "react-toastify";
import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const CustomersIdProofPdf = () => {
  Font.register({
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  });

  const { id } = useParams();

  const [getIdProof, setgetIdProof] = useState(null);

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/users/${id}`, config)
      .then((res) => {
        console.log(res.data.data);
        if (res !== undefined) {
          setgetIdProof(res.data.data[0]);
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <>
      <div className="w-full pb-5 flex justify-end">
        <Link to={`/homepage/user/userdetailpage/${id}`}>
          <button className="px-8 py-2 bg-gray-800 text-white hover:bg-gray-700 duration-500 rounded">
            Go Back
          </button>
        </Link>
      </div>
      {/* <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document>
          <Page>
            <Image src={`${imageUrl}/${getIdProof?.id_proof}`} alt="id_proof"/>
          </Page>
        </Document>
      </PDFViewer> */}
      <div className="w-full h-[500px]" >
        <img  src={`${imageUrl}/${getIdProof?.id_proof}`} alt="id_proof" className="w-full h-full object-contain"/>
      </div>
    </>
  );
};

export default CustomersIdProofPdf;
