import React from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../components/SideBar";
import NavBar from "../components/NavBar";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { useState, useEffect } from "react";
import { AppContext } from "./AppContext";

const apiUrl = process.env.REACT_APP_API_URL;

const HomePage = () => {
  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const [item, setItem] = useState([]);

  const [getMemberId, SetGetMemberId] = useState();

  useEffect(() => {
    axios
      .get(`${apiUrl}/doctorsemail`)
      .then((res) => {
        setItem(res.data.data);
        //  console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .post(
        `${apiUrl}/doctors/getemail`,
        {
          code: localStorage.getItem("weightloss_code"),
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token_id")}`,
          },
        }
      )
      .then((res) => {
        setImg(res.data.data);
        SetGetMemberId(res.data.data[0].id);
        console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [img, setImg] = useState([]);

  const [pic, setPic] = useState([]);

  const [getName, setGetName] = useState([]);

  useEffect(() => {
    const picture = img && img.map((d) => d).map((i) => i.image);
    setPic(picture);

    const memberName = img && img.map((d) => d).map((i) => i.first_name);
    setGetName(memberName);
  }, [img]);

  const handleReload = () => {
    axios
      .post(
        `${apiUrl}/doctors/getemail`,
        {
          code: localStorage.getItem("weightloss_code"),
        },
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token_id")}`,
          },
        }
      )
      .then((res) => {
        setImg(res.data.data);
        // console.log(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [getRoleName, setGetRoleName] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/roles/${localStorage.getItem("weightloss_role")}`, config)
      .then((res) => {
        console.log("role", res.data.data);
        if (res !== undefined) {
          setGetRoleName(res.data.data[0].name);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let isRefreshing = false; // Flag to track if token refresh is already in progress

  async function checkTokenExpiration() {
    const currentDate = new Date();
    const token = localStorage.getItem("token_id");

    if (token && !isRefreshing) {
      // Check if token is present and refresh is not already in progress
      const decodedToken = jwt_decode(token);
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        isRefreshing = true; // Set the flag to indicate that token refresh is in progress
        try {
          const res = await axios.post(`${apiUrl}/auth/refreshtoken`, {
            token: localStorage.getItem("refreshtoken_id"),
            email: item,
          });
          localStorage.setItem("token_id", res.data.accessToken);
          localStorage.setItem("refreshtoken_id", res.data.refreshToken);
          window.location.reload(); // Reload the page
          // console.log(res);
          isRefreshing = false; // Reset the flag once token refresh is complete
        } catch (error) {
          console.log(error);
          isRefreshing = false; // Reset the flag in case of error during token refresh
        }
      }
    }
  }

  axios.interceptors.request.use(
    (config) => {
      checkTokenExpiration();
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return (
    <div>
      <AppContext.Provider value={{ getMemberId }}>
        <NavBar pic={pic} getName={getName} getRoleName={getRoleName} />
        <SideBar getRoleName={getRoleName} />
        <div className="flex flex-col absolute left-1/5 right-0 top-16 w-full lg:w-4/5 pt-8 px-5 sm:px-8 z-0 bg-gray-50">
          <Outlet context={[pic, getRoleName, handleReload, getName]} />
        </div>
      </AppContext.Provider>
    </div>
  );
};

export default HomePage;
