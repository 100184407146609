import React from "react";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import axios from "axios";
import { toast } from "react-toastify";
import { Select } from "antd";
import "../../App.css";
import dispatchorder from "../../images/1.png";
import neworder from "../../images/2.png";
import approvedorder from "../../images/3.png";
import todayApprovedorder from "../../images/5.png";
import todayNeworder from "../../images/4.png";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const apiUrl = process.env.REACT_APP_API_URL;

const DashBoard = () => {
  const [filteredData, setFilteredData] = useState([]);

  const [item, setItem] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const getData = localStorage.getItem("weightloss_role");

  const [getDashboardValues, setGetDashboardValues] = useState([]);

  const getStatus = (statusValue) => {
    switch (statusValue) {
      case 0:
        return "Rejected";

      case 2:
        return "Approved";

      case 3:
        return "Ready for dispatch";

      default:
        return "New";
    }
  };

  const columns = [
    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[160px]">
          Order ID
        </h1>
      ),
      selector: (row) => row.id,
      sortable: true,
      width: "160px",
    },
    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[220px]">
          Customer Name
        </h1>
      ),
      selector: (row) => row.username,
      sortable: true,
      width: "220px",
    },
    {
      name: <h1 className="text-lg text-gray-950 font-semibold">Mail ID</h1>,
      selector: (row) => row.user_email,
      sortable: true,
    },

    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[160px]">Date</h1>
      ),
      selector: (row) => dayjs(row.created_at).format("DD-MM-YYYY"),
      sortable: true,
      width: "160px",
    },
    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[240px]">
          Status
        </h1>
      ),
      selector: (row) => getStatus(row.status),
      width: "240px",
    },
  ];

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  useEffect(() => {
    if (getData === "4") {
      axios
        .get(`${apiUrl}/orderstatus2`, config)
        .then((res) => {
          console.log(res.data.data);
          setItem(res.data.data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else if (getData === "2") {
      axios
        .get(`${apiUrl}/orderstatus1`, config)
        .then((res) => {
          console.log(res.data.data);
          setItem(res.data.data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else {
      axios
        .get(`${apiUrl}/orders`, config)
        .then((res) => {
          console.log(res.data.data);
          setItem(res.data.data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }

    if (getData === "1") {
      axios
        .get(`${apiUrl}/orders/getdashboardvalues`, config)
        .then((res) => {
          console.log(res.data);
          setGetDashboardValues(res.data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  }, []);

  useEffect(() => {
    const filterItem = (item) => {
      return Object.values(item)?.some((value) => {
        if (typeof value === "string") {
          const lowerCaseValue = value.toString().toLowerCase();
          return lowerCaseValue.includes(searchTerm.toLowerCase());
        } else if (value instanceof Date) {
          const dateValue = value.toISOString().toLowerCase();
          return dateValue.includes(searchTerm.toLowerCase());
        } else if (typeof value == "number") {
          const stringValue = value.toString().toLowerCase();
          return stringValue === searchTerm.toLowerCase();
        }
        return false;
      });
    };

    const filteredResult = item?.filter(filterItem);

    setFilteredData(filteredResult?.length >= 1 ? filteredResult : []);
  }, [item, searchTerm]);

  const navigate = useNavigate();

  const { id } = useParams();

  const customStyles = {
    header: {
      style: {
        textAlign: "center", // Center header text
      },
    },
    cells: {
      style: {
        textAlign: "center", // Center cell text
      },
    },
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "40px", // override the cell padding for head cells
        // paddingRight: "20px",
        backgroundColor: "#FBF7ED",
        color: "#1F2937",
        fontWeight: "400",

        // border: '1px solid #ddd',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#fff9f3",

        // borderBottomColor: "#FFFFFF",
        // outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        paddingLeft: "40px",
        paddingRight: "40px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "15px",
        color: "#1F2937",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  const orders = [
    {
      id: "allorders",
      order: "All Orders",
    },
    {
      id: 1,
      order: "New Orders",
    },

    {
      id: 2,
      order: "Approved Orders",
    },
    {
      id: 0,
      order: "Rejected Orders",
    },
    {
      id: 3,
      order: "Ready for Dispatch",
    },
  ];

  const prescriberOrders = [
    {
      id: 1,
      order: "New Orders",
    },

    {
      id: 2,
      order: "Approved Orders",
    },
    {
      id: 0,
      order: "Rejected Orders",
    },
  ];

  console.log(item, "weuver");

  const handleRowClick = (row) => {
    // navigate("/homepage/viewDetails/new/" + row.id + "/" + row.assesment_id);
    navigate(`/homepage/dashboard/viewdetails/${row.id}/${row.user_id}`);
  };

  const onChange = (value) => {
    console.log(`selected ${value}`);

    if (value === "allorders") {
      axios
        .get(`${apiUrl}/orders`, config)
        .then((res) => {
          console.log(res.data.data);
          setItem(res.data.data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else if (value === 0) {
      axios
        .get(`${apiUrl}/orderstatus0`, config)
        .then((res) => {
          console.log(res.data.data);
          setItem(res.data.data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else if (value === 1) {
      axios
        .get(`${apiUrl}/orderstatus1`, config)
        .then((res) => {
          console.log(res.data.data);
          setItem(res.data.data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else if (value === 2) {
      axios
        .get(`${apiUrl}/orderstatus2`, config)
        .then((res) => {
          console.log(res.data.data);
          setItem(res.data.data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else if (value === 3) {
      axios
        .get(`${apiUrl}/orderstatus3`, config)
        .then((res) => {
          console.log(res.data.data);
          setItem(res.data.data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const options = orders.map((order) => ({
    value: order.id, // Set a unique value for each course
    label: order.order, // Display name for the course
  }));

  const filterOption2 = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const options2 = prescriberOrders.map((order) => ({
    value: order.id, // Set a unique value for each course
    label: order.order, // Display name for the course
  }));

  const handleDateRange = (dates) => {
    if (dates !== null) {
      const startDate = dates[0];
      const endDate = dates[1];

      const sendStartDate = dayjs(startDate).format("YYYY-MM-DD HH:mm:ss");
      const sendEndDate = dayjs(endDate).format("YYYY-MM-DD HH:mm:ss");

      axios
        .get(`${apiUrl}/orders/${sendStartDate}/${sendEndDate}`, config)
        .then((res) => {
          console.log(res);
          if (res !== undefined) {
            if (res.data.status === 404) {
              toast.info(res.data.message);
            } else if (res.data.status === 200) {
              setItem(res.data.data);
            }
          }
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  return (
    <>
      <div className="w-full h-fit flex flex-col gap-8 pb-8">
        {getData === "1" && (
          <div className="w-full h-fit flex flex-row gap-10">
            <div className="w-full h-[150px] flex flex-col justify-center items-center gap-5">
              <div className="w-full h-full flex flex-row justify-center items-center gap-5">
                <div className="w-[20%] h-full bg-white rounded drop-shadow border flex flex-col justify-center items-center cursor-pointer p-3 lg:p-4">
                  <div className="h-1/2">
                    <h1 className="text-[16px] lg:text-lg text-gray-700 font-medium ">
                      New Orders
                    </h1>
                  </div>

                  <div className="w-full h-1/2 flex flex-row justify-between items-center gap-4 ">
                    <div className="w-full h-full flex justify-center items-center">
                      <img
                        src={neworder}
                        className="w-[100px] h-[50px] object-contain"
                      />
                    </div>
                    <div className="w-full h-full flex items-center justify-center">
                      <h1 className="text-primary text-xl font-bold px-5 py-2 border rounded-md bg-white drop-shadow-sm">
                        {getDashboardValues?.newOrdersCountValue}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="w-[20%] h-full bg-white rounded drop-shadow border flex flex-col justify-center items-center cursor-pointer p-3 lg:p-4">
                  <div className="h-1/2">
                    <h1 className="text-[16px] lg:text-lg text-gray-700 font-medium text-center">
                      Approved Orders
                    </h1>
                  </div>
                  <div className="w-full h-1/2 flex flex-row justify-between items-center gap-4 ">
                    <div className="w-full h-full flex justify-center items-center">
                      <img
                        src={approvedorder}
                        className="w-[120px] h-[65px] object-contain"
                      />
                    </div>
                    <div className="w-full h-full flex items-center justify-center">
                      <h1 className="text-primary text-xl font-bold px-5 py-2 border rounded-md bg-white drop-shadow-sm">
                        {getDashboardValues?.approveOrderstoDispenseCountValue}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="w-[20%] h-full bg-white rounded drop-shadow border flex flex-col justify-center items-center cursor-pointer p-3 lg:p-4">
                  <div className="h-1/2">
                    <h1 className="text-[16px] lg:text-lg text-gray-700 font-medium text-center">
                      Dispensed Orders
                    </h1>
                  </div>
                  <div className="w-full h-1/2 flex flex-row justify-between items-center gap-4 ">
                    <div className="w-full h-full flex justify-center items-center">
                      <img
                        src={dispatchorder}
                        className="w-[100px] h-[50px] object-contain"
                      />
                    </div>
                    <div className="w-full h-full flex items-center justify-center">
                      <h1 className="text-primary text-xl font-bold px-5 py-2 border rounded-md bg-white drop-shadow-sm">
                        {getDashboardValues?.dispenseOrdersCountValue}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="w-[20%] h-full bg-white rounded drop-shadow border flex flex-col justify-center items-center cursor-pointer p-3 lg:p-4">
                  <div className="h-1/2">
                    <h1 className="text-[16px] lg:text-lg text-gray-700 font-medium text-center">
                      Today New Orders
                    </h1>
                  </div>
                  <div className="w-full h-1/2 flex flex-row justify-between items-center gap-4 ">
                    <div className="w-full h-full flex justify-center items-center">
                      <img
                        src={todayNeworder}
                        className="w-[100px] h-[45px] object-contain"
                      />
                    </div>
                    <div className="w-full h-full flex items-center justify-center">
                      <h1 className="text-primary text-xl font-bold px-5 py-2 border rounded-md bg-white drop-shadow-sm">
                        {getDashboardValues?.newOrdersByTodayCountValue}
                      </h1>
                    </div>
                  </div>
                </div>
                <div className="w-[20%] h-full bg-white rounded drop-shadow border flex flex-col justify-center items-center cursor-pointer p-3">
                  <div className="h-1/2">
                    <h1 className="text-[16px] lg:text-lg text-gray-700 font-medium text-center">
                      Today Approved Orders
                    </h1>
                  </div>
                  <div className="w-full h-1/2 flex flex-row justify-between items-center gap-4 ">
                    <div className="w-full h-full flex justify-center items-center">
                      <img
                        src={todayApprovedorder}
                        className="w-[100px] h-[50px] object-contain"
                      />
                    </div>
                    <div className="w-full h-full flex items-center justify-center">
                      <h1 className="text-primary text-xl font-bold px-5 py-2 border rounded-md bg-white drop-shadow-sm">
                        {getDashboardValues?.approvedOrdersByTodayCountValue}
                      </h1>
                    </div>
                  </div>
                </div>

                {/* <div className="w-1/3 h-full bg-white rounded drop-shadow border flex justify-center items-center">
                  <h1>{getDashboardValues?.dispenseOrdersCountValue}</h1>
                </div> */}
                {/* <div className="w-[20%] h-full bg-white rounded drop-shadow border flex justify-center items-center">
                  <h1>{getDashboardValues?.newOrdersByTodayCountValue}</h1>
                </div>
                <div className="w-[20%] h-full bg-white rounded drop-shadow border flex justify-center items-center">
                  <h1>{getDashboardValues?.approvedOrdersByTodayCountValue}</h1>
                </div> */}
              </div>
              {/* <div className="w-full h-full flex flex-row justify-center items-center gap-5">
                
                <div className="w-1/3 h-full bg-white rounded drop-shadow border flex justify-center items-center"></div>
              </div> */}
            </div>
            {/* <div className="w-4/12 h-[300px] bg-white rounded drop-shadow border"></div> */}
          </div>
        )}

        {getData === "2" && (
          <h1 className="text-2xl md:text-3xl font-bold text-gray-600">
            Orders
          </h1>
        )}

        {getData === "4" && (
          <h1 className="text-2xl md:text-3xl font-bold text-gray-600">
            Orders
          </h1>
        )}

        <div className="w-full h-fit p-5 flex flex-col bg-white border drop-shadow rounded">
          <div className="w-full h-12 flex flex-row items-center my-6 md:my-4 gap-10">
            <input
              placeholder="search..."
              type="text"
              required
              className="p-2 text-md border border-gray-400 
        rounded focus:border-primary focus:bg-white focus:text-black focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none"
              onChange={(event) => setSearchTerm(event.target.value)}
              value={searchTerm}
            />
            {getData === "1" && (
              <Select
                showSearch
                placeholder="Select a order type"
                optionFilterProp="children"
                onChange={onChange}
                // onSearch={onSearch}
                filterOption={filterOption}
                options={options}
                className="w-[220px] h-[43px] border border-gray-300 rounded-md"
              />
            )}
            {getData === "2" && (
              <Select
                showSearch
                placeholder="Select a order type"
                optionFilterProp="children"
                onChange={onChange}
                // onSearch={onSearch}
                filterOption={filterOption2}
                options={options2}
                className="w-[220px] h-[43px] border border-gray-300 rounded-md"
              />
            )}
            <RangePicker
              onChange={handleDateRange}
              className="w-[260px] h-[43px] border border-gray-300 rounded-md"
            />
          </div>
          <div className="mt-6">
            <DataTable
              fixedHeader={true}
              columns={columns}
              data={filteredData}
              pagination
              customStyles={customStyles}
              highlightOnHover
              pointerOnHover
              fixedHeaderScrollHeight="310px"
              onRowClicked={handleRowClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashBoard;
