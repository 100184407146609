import React from "react";
import { useState, useEffect } from "react";
import { FaUserCircle } from "react-icons/fa";
import axios from "axios";
import { toast} from "react-toastify";
import { useParams, useNavigate } from "react-router";
import { useOutletContext } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_URL;

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const Setting = () => {
  const [pic, getRoleName, handleReload, getName] = useOutletContext();

  const initialstate = {
    first_name: "",
    surname: "",
    email: "",
    phoneno: "",
    password: "",
    role: "",
    code: "",
    sign_image: "",
    description: "",
    image: "",
    registration_no: "",
    address_line1: "",
    address_line2: "",
    city: "",
    postcode: "",
  };

  const [form, setForm] = useState(initialstate);

  const [cmpName, setCmpName] = useState([]);

  const [signImage, setSignImage] = useState(null);

  const [profile, setProfile] = useState(null);

  // const [getRoleName, setGetRoleName] = useState([]);

  const [reloadName, setReloadName] = useState();

  const navigate = useNavigate();

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
      "Content-Type": "multipart/form-data",
    },
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/roles`, config)
      .then((res) => {
        //  console.log("role",res.data.data);
        setCmpName(res.data.data);
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, []);

  // useEffect(() => {
  //   axios
  //     .get(`${apiUrl}/roles/${id}`, config)
  //     .then((res) => {
  //       //  console.log("role",res.data.data);
  //       setGetRoleName(res.data.data)
  //     })
  //     .catch((err) => {
  //       toast.error(err, {
  //         position: toast.POSITION.TOP_CENTER,
  //       });
  //     });
  // }, []);

 

  const onUploadSignImage = (event) => {
    setSignImage(event.target.files[0]);
  };

  const onUploadProfile = (event) => {
    setProfile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append("first_name", form.first_name);
    formData.append("surname", form.surname);
    formData.append("phoneno", form.phoneno);
    formData.append("password", form.password);
    formData.append("image", profile);
    formData.append("sign_image", signImage);
    formData.append("description", form.description);
    formData.append("registration_no", form.registration_no);
    formData.append("address_line1", form.address_line1);
    formData.append("address_line2", form.address_line2);
    formData.append("city", form.city);
    formData.append("postcode", form.postcode);
    formData.append("created_by", 1);


    //  for (let i = 0; i < profile.length; i++) {
    //    formData.append("image", profile[i]);
    //  }

    await axios
      .put(`${apiUrl}/doctors/setting/${id}`, formData, config)
      .then((res) => {
        // console.log(res.data);
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "fixed top-14 right-1",
          });
          handleReload();
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "fixed top-14 right-1",
          });
        }
      });
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getFormData(id);
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/doctors/setting/${id}`, config);
    // console.log("hello", formData);

    if (formData.status === 200) {
      setForm({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  // useEffect(() => {
  //   const getRole = getRoleName && getRoleName.map((d) => d.name);
  //   setReloadName(getRole);
  // }, [getRoleName]);

  // console.log(pic);

  return (
    <div
      className="flex flex-col w-full px-6 pb-10 gap-8 bg-white"
    >
      <div className="flex flex-col justify-center items-center gap-2">
        {pic[0] === "undefined" || pic[0] === "" ? (
          <>
            <div className="w-[135px] h-[135px] border rounded-full p-0.5 ">
              <FaUserCircle className="w-full h-full text-gray-400" />
            </div>
            {getName !== "undefined" && getRoleName !== "undefined" && (
              <>
                {" "}
                <h1 className="text-[20px] text-gray-500 font-bold capitalize">
                  {getName}  - {getRoleName}
                </h1>
              </>
            )}
          </>
        ) : (
          <>
            {" "}
            {pic.map((i) => (
              <React.Fragment key={i.id}>
                <div className="w-[135px] h-[135px] border rounded-full p-0.5 object-cover">
                  <img
                    src={`${imageUrl}/${i}`}
                    className="w-full h-full rounded-full object-cover"
                    alt="profile"
                  />
                </div>
                {getName !== "undefined" && getRoleName !== "undefined" && (
                  <>
                    {" "}
                    <h1 className="text-[20px] text-gray-500 font-bold capitalize">
                      {getName} - {getRoleName}
                    </h1>
                  </>
                )}
              </React.Fragment>
            ))}
          </>
        )}
      </div>
      <div className="flex flex-col w-full h-fit px-4 sm:px-6 gap-2 border bg-white">
        <div className="flex flex-col justify-center items-center w-full  h-fit">
          <div className="flex flex-rows justify-center items-center w-full  h-12 my-8 ">
            <h2 className="text-xl md:text-2xl  font-semibold text-gray-500 w-full">
              Edit Profile
            </h2>
            {/* <Link to="/homepage/membermenu/doctors">
              <FaTimes
                className="w-12 h-6 cursor-pointer text-gray-500"
                // onClick={() => setToggle(!toggle)}
              />
            </Link> */}
          </div>

          <div className="w-full h-[3px] bg-gray-300 rounded-full"></div>
        </div>
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="w-full h-fit grid grid-cols-1  gap-3 sm:gap-6 ">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
              <div className="h-24 grid grid-cols-1  items-center">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-gray-500">
                      {" "}
                      First Name{" "}
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <div className="grid grid-cols-1">
                  <input
                    type="text"
                    required
                    name="first_name"
                    value={form.first_name}
                    onChange={(e) =>
                      setForm({ ...form, first_name: e.target.value })
                    }
                    placeholder="first_name"
                    className="p-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                  />
                </div>
              </div>
              <div className="h-24 grid grid-cols-1  items-center">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-gray-500">
                      {" "}
                      Surname{" "}
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <div className="grid grid-cols-1">
                  <input
                    type="text"
                    required
                    name="surname"
                    value={form.surname}
                    onChange={(e) =>
                      setForm({ ...form, surname: e.target.value })
                    }
                    placeholder="surname"
                    className="p-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                  />
                </div>
              </div>
              <div className="h-24 grid grid-cols-1 items-center">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-gray-500">
                      {" "}
                      Password{" "}
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <div className="grid grid-cols-1">
                  <input
                    type="password"
                    required
                    name="password"
                    // value={form.password}
                    onChange={(e) =>
                      setForm({ ...form, password: e.target.value })
                    }
                    placeholder="Password"
                    className="p-2 border border-gray-300 rounded
          text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
          dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
                  />
                </div>
              </div>
              <div className="h-24 grid grid-cols-1 items-center">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-gray-500">
                      {" "}
                      Email Address{" "}
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <input
                  required
                  name="email"
                  value={form.email}
                  readOnly
                  onChange={(e) => setForm({ ...form, email: e.target.value })}
                  placeholder="Email"
                  type="email"
                  className="p-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        "
                />
              </div>
              <div className="h-24 grid grid-cols-1 items-center gap-2">
                <div>
                  <label className="text-lg font-medium text-gray-500">
                    Please Upload Your Profile

                  </label>
                </div>
                <input
                  name="image"
                  multiple
                  // value={form.image}
                  onChange={onUploadProfile}
                  className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 text-base font-normal text-gray-400 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-2 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-primary file:px-3 file:py-2 file:text-white file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-secondary focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
                  type="file"
                />
              </div>
              <div className="h-24 grid grid-cols-1 items-center gap-2">
                <div>
                  <label className="text-lg font-medium text-gray-500">
                    Signature Image
                  </label>
                </div>
                <input
                  name="sign_image"
                  multiple
                  // value={form.image}
                  onChange={onUploadSignImage}
                  className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 text-base font-normal text-gray-400 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-2 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-primary file:px-3 file:py-2 file:text-white file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-secondary focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
                  type="file"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
              <div className="h-24 grid grid-cols-1 items-center">
                <div>
                  <label className="text-lg font-medium text-gray-500">
                    Phone Number
                   

                  </label>
                </div>
                <input
                  value={form.phoneno}
                  name="phoneno"
                  onChange={(e) =>
                    setForm({ ...form, phoneno: e.target.value })
                  }
                  placeholder="Phone no"
                  type="text"
                  className="p-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                />
              </div>
              <div className="h-24 grid grid-cols-1 items-center">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-gray-500">
                      {" "}
                      Roles{" "}
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <div className="grid grid-cols-1">
                  <select
                    disabled
                    required
                    name="role"
                    value={form.role}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        role: e.target.value,
                      })
                    }
                    className="py-[9px] px-2 border border-gray-300 rounded
          text-md text-gray-500 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none"
                  >
                    <option
                      value="Select - Below"
                      className="p-2 border border-gray-500 rounded
          text-md text-gray-400"
                    >
                      Select - Below
                    </option>

                    {cmpName.map((role) => {
                      return (
                        <React.Fragment key={role.id}>
                          <option
                            value={role.id}
                            className=" px-2 border border-gray-300 rounded
          text-md text-gray-500"
                          >
                            {role.name}
                          </option>
                        </React.Fragment>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="h-24 grid grid-cols-1 items-center">
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-gray-500">
                    {" "}
                    Registration No{" "}
                  </span>
                  {form.role !== "4" && (
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  )}
                </label>
                <input
                  value={form.registration_no}
                  name="phoneno"
                  onChange={(e) =>
                    setForm({ ...form, registration_no: e.target.value })
                  }
                  placeholder="Registration no"
                  type="text"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
            <div className="h-fit grid grid-cols-1 items-center gap-2">
              <div>
                <label className="text-lg font-medium text-gray-500">
                  Address Line 1
                </label>
              </div>
              <textarea
                name="address line 1"
                value={form.address_line1}
                onChange={(e) =>
                  setForm({ ...form, address_line1: e.target.value })
                }
                placeholder="address line 1"
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
            <div className="h-fit grid grid-cols-1 items-center gap-2">
              <div>
                <label className="text-lg font-medium text-gray-500">
                  Address Line 2
                </label>
              </div>
              <textarea
                name="address line 2"
                value={form.address_line2}
                onChange={(e) =>
                  setForm({ ...form, address_line2: e.target.value })
                }
                placeholder="address line 2"
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="h-24 grid grid-cols-1 items-center">
              <div>
                <label className="text-lg font-medium text-gray-500">
                  City
                </label>
              </div>
              <input
                value={form.city}
                name="city"
                onChange={(e) => setForm({ ...form, city: e.target.value })}
                placeholder="city"
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
            <div className="h-24 grid grid-cols-1 items-center">
              <div>
                <label className="text-lg font-medium text-gray-500">
                  Postcode
                </label>
              </div>
              <input
                value={form.postcode}
                name="postcode"
                onChange={(e) => setForm({ ...form, postcode: e.target.value })}
                placeholder="Postcode"
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div>
            <div className="h-24 grid grid-cols-1 items-center">
              <div>
                <label className="text-lg font-medium text-gray-500">Bio</label>
              </div>
              <textarea
                name="description"
                value={form.description}
                onChange={(e) =>
                  setForm({ ...form, description: e.target.value })
                }
                placeholder="Enter your bio..."
                type="text"
                className="p-2 border border-gray-300 rounded h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>

            <div className="flex flex-row justify-center items-center my-24 gap-6 ">
              <button
                className="py-2 px-7 bg-primary text-white shadow shadow-primary hover:bg-secondary ease-in-out duration-300 text-normal font-semibold
      rounded-md "
                type="submit"
              >
                Update
              </button>
            </div>
          </div>

        </form>
      </div>
    </div>
  );
};

export default Setting;
