import React from "react";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import axios from "axios";
import { toast} from "react-toastify";
import Loading from "../../subComponents/Loading";

const apiUrl = process.env.REACT_APP_API_URL;

const Referral = () => {
  const [filteredData, setFilteredData] = useState([]);

  const [item, setItem] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const columns = [
    {
      name: <h1 className="text-lg text-black">User Name</h1>,
      selector: (row) => row.username,
    },
    {
      name: (
        <h1 className="text-lg text-black">
          Referred Username
        </h1>
      ),
      selector: (row) => row.referred_username,
    },
    {
      name: (
        <h1 className="text-lg text-black">Referral Code</h1>
      ),
      selector: (row) => row.referral_code,
    },
    {
      name: <h1 className="text-lg text-black">Actions</h1>,
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-2">
            <MdDelete
              onClick={() => handleDelete(row.id)}
              className="w-8 h-6 text-red-500 cursor-pointer"
            />
            <Link to={`/homepage/referral/referralformedit/${row.id}`}>
              <FiEdit className="w-8 h-5 text-blue-500 cursor-pointer" />
            </Link>
          </div>
        </>
      ),
    },
  ];

   const config = {
     headers: {
       authorization: `Bearer ${localStorage.getItem("token_id")}`,
     },
   };

  const handleDelete = async (id) => {
    confirmAlert({
      title: "Are you sure",
      message: "You want to delete this data ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await axios.delete(`${apiUrl}/referral/${id}`, config).then((res) => {
              //  console.log(res.status);
              if (res.data.status === 200) {
                setFilteredData((prevData) =>
                  prevData.filter((item) => item.id !== id)
                );
                toast.success(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "fixed top-14 right-1",
                });
              } else {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "fixed top-14 right-1",
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            toast.warn("cancelled", {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          },
        },
      ],
    });
  };

   const [pending, setPending] = useState(true);

  useEffect(() => {
   
    axios
      .get(`${apiUrl}/referral`, config)
      .then((res) => {
       if (res.data !== undefined) {
         if (res.status === 200) {
           setItem(res.data.data);
           setPending(false);
           console.log("false")
         }
       }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, []);

  useEffect(() => {
    const result =
      item &&
      item.filter((value) => {
         return value.username.toLowerCase().match(searchTerm.toLowerCase());
      });
    setFilteredData(result);
  }, [item, searchTerm]);

    const customStyles = {
      rows: {
        style: {
          minHeight: "48px", // override the row height
          minWidth: "800px",
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
          backgroundColor: "#FBF7ED",
          color: "000000",
          
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
          fontSize: "16px",
          color: "#364353",
        },
      },
    };


  return (
    <>
      {pending ? (
        <><Loading/></>
      ) : (
        <div>
          <div className="flex flex-row justify-between w-full h-16 items-start gap-1">
            <div>
              <h4 className="text-2xl md:text-3xl font-bold text-globalBlack">
                Referral
              </h4>
            </div>
          
          </div>
          <div className="w-full h-12 flex flex-row justify-between items-center">
            <input
              placeholder="Search..."
              type="text"
              required
              className="p-2 text-lg border border-gray-400 
        rounded focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
          dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
              onChange={(event) => setSearchTerm(event.target.value)}
              value={searchTerm}
            />
              <div>
              <Link to="/homepage/referral/referralform">
                <button
                  className="py-1.5 px-4 md:py-2 md:px-7 text-md md:text-lg bg-primary text-white shadow shadow-primary  hover:bg-secondary hover:shadow-primary ease-in-out duration-300 rounded md:rounded font-semibold flex flex-row 
          items-center gap-1"
                >
                  <FaPlus />
                  Add
                </button>
              </Link>
            </div>
          </div>
          <div className="mt-10">
            <DataTable
              columns={columns}
              data={filteredData}
              fixedHeader
              pagination
              bordered
              customStyles={customStyles}
              fixedHeaderScrollHeight="240px"
            />
          </div>
        
        </div>
      )}
        
    </>
  );
};

export default Referral;
