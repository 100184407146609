import React from "react";
import { useState } from "react";
import ProductForm from "./ProductForm";
import ProductForm1 from "./ProductForm1";

const ProductPage = () => {
  const [page, setPage] = useState(0);
  
  console.log(page)

  const FormTiles = ["Basic Details", "Frequently Asked Question "];

  const pageDisplay = () => {
    if (page === 0) {
      return <ProductForm page={page} setPage={setPage} />;
    } else {
      return <ProductForm1 page={page} setPage={setPage} />;
    }
  };

  return (
    <div
    >
      <div className="flex flex-rows justify-center items-center w-full snap-start h-24 border-b-2 border-gray-300">
        <h2 className="text-xl md:text-2xl font-semibold text-globalBlack w-full">
          Medicines Form
        </h2>
      </div>
     
      <div className="text-xl md:text-2xl font-semibold my-5 text-globalBlack">
        <h1>{FormTiles[page]}</h1>
      </div>

      {pageDisplay()}

      {/* <div className="h-16 w-full mb-24"></div> */}
    </div>
  );
};

export default ProductPage;
