import React, { useEffect, useState } from "react";
import { Document, Font, Page, Text, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_API_URL;

const DispenserLabel = () => {
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    body1: {
      paddingHorizontal: 8,
      paddingVertical: 8,
    },
    title: {
      fontSize: 8,
      textAlign: "center",
      fontWeight: "extrabold",
      color: "black",
      marginBottom: 1,
    },
    titletwo: {
      fontSize: 8,
      textAlign: "center",
      fontWeight: "extrabold",
      color: "black",
      marginBottom: 2,
      textTransform: "capitalize",
    },
    title3: {
      fontSize: 6,
      textAlign: "center",

      // color: "black",
    },
    titlethree: {
      fontSize: 6,
      textAlign: "center",

      // color: "black",
    },
    title4: {
      fontSize: 6,
      marginTop: 18,
      marginBottom: 18,
    },
    titlefour: {
      fontSize: 6,
      marginTop: 8,
      marginBottom: 2,
    },
    title2: {
      fontSize: 6,
      // color: "gray",
    },
    address: {},
    author: {
      fontSize: 12,
      marginBottom: 20,
    },
    author2: {
      fontSize: 12,
    },
    author3: {
      fontSize: 12,
      marginBottom: 172,
    },
    author1: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 40,
      color: "gray",
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: "Oswald",
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
      fontFamily: "Times-Roman",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    h1: {
      fontSize: 14,
      textAlign: "left",
      // textDecoration: "underline",
    },
    h2: {
      fontSize: 14,
      textAlign: "right",
      // textDecoration: "underline",
    },

    flex: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignContent: "center",
      marginBottom: 30,
      columnGap: "5px",
    },

    flextwo: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      marginTop: 3,
      columnGap: "2px",
    },
    flex2: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-start",
      columnGap: "2px",
    },
    flex1: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignContent: "flex-start",
      rowGap: "15px",
      marginBottom: 20,
    },
    content: {
      padding: 20,
      "@media max-width: 400": {
        flexDirection: "column",
      },
      "@media min-width: 400": {
        flexDirection: "row",
      },
    },
    box: {
      width: "72px",
      height: "20px",
      border: "1px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
    },
    box1: {
      width: "100%",
      height: "20px",
      border: "1px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
    },
    table: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
    },
    tabledata: {
      position: "absolute",
      top: "2px",
      left: "2px",
    },
    tablebox: {
      width: "25px",
      height: "20px",
      borderLeft: "1px",
      borderTop: "1px",
      borderBottom: "1px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
    },
    tablebox1: {
      width: "25px",
      height: "20px",
      border: "1px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
    },
    Date: {
      fontSize: 6,
      marginTop: 8,
    },
  });

  Font.register({
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  });

  const { id, user_id } = useParams();

  const [item, setItem] = useState("");


  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  const [orderItem, setOrderItem] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/orders/${id}`, config)
      .then((res) => {
        console.log(res.data.data);
        setItem(res.data.data[0]?.delivery_address);
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });

    axios
      .get(`${apiUrl}/getorderitems/${id}`)
      .then((res) => {
        console.log(res.data.data);
        if (res !== undefined) {
          setOrderItem(res.data.data);
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, []);

  const today = new Date();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="w-full pb-5 flex justify-end">
        <Link to={`/homepage/dashboard/viewdetails/${id}/${user_id}`}>
          <button className="px-8 py-2 bg-gray-800 text-white hover:bg-gray-700 duration-500 rounded">
            Go Back
          </button>
        </Link>
      </div>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document>
          {orderItem?.map((value) => (
            <Page
              size={[(72 * 7.2) / 2.54, (72 * 3.6) / 2.54]}
              style={styles.body1}
              key={value.id}
            >
              <Text style={styles.title}>
                {value.traveller_name} (Delivery)
              </Text>
              <Text style={styles.title3}>{item && item}</Text>
              <Text style={styles.title3}>SW16 5RW</Text>

              <Text style={styles.title4}>189441283</Text>
              
              <view style={styles.flex2}>
                <view>
                  <Text style={styles.title2}>
                    Prescription Counter, 160 Gillett Rd,
                  </Text>
                  <Text style={styles.title2}>
                    Thornton Health, CR7 8SN Tel 02036593149
                  </Text>
                </view>
                <Text style={styles.title2}>
                  {`${today.getDate()}-${
                    today.getMonth() + 1
                  }-${today.getFullYear()}`}
                </Text>
              </view>
              <view>
                <Text style={styles.titletwo}>{value.medicine_name}</Text>

                <Text style={styles.title3}>Four Times A Day</Text>
                <Text style={styles.title3}>
                  FOR EXTERNAL USE ONLY Get a new supply 4 weeks
                </Text>
                <Text style={styles.title3}>
                  after first opening container. Date opened ..............
                </Text>
                <view style={styles.flex2}>
                  <Text style={styles.titlefour}>{value.traveller_name}</Text>

                  <Text style={styles.Date}>
                    {`${today.getDate()}-${
                      today.getMonth() + 1
                    }-${today.getFullYear()}`}
                  </Text>
                </view>
                <Text style={styles.title3}>Keep out of reach and sight</Text>
                <Text style={styles.title3}>of children</Text>
                <view style={styles.flextwo}>
                  <view>
                    <Text style={styles.title2}>
                      Prescription Counter, 160 Gillett Rd,
                    </Text>
                    <Text style={styles.title2}>
                      Thornton Health, CR7 8SN Tel 02036593149
                    </Text>
                  </view>

                  <view style={styles.table}>
                    <view style={styles.tablebox}>
                      <Text style={styles.title2}>DispBy</Text>
                    </view>
                    <view style={styles.tablebox1}>
                      <Text style={styles.title2}>ChkBy</Text>
                    </view>
                  </view>
                </view>
              </view>
            </Page>
          ))}
        </Document>
      </PDFViewer>
    </>
  );
};

export default DispenserLabel;
