import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import { toast} from "react-toastify";
import { useParams, useNavigate } from "react-router";

const apiUrl = process.env.REACT_APP_API_URL;

const ConfigsForm = () => {

  const initialstate = {
    first_referral_percentage: "",
    user_referral_percentage: "",
  };

  const [form, setForm] = useState(initialstate);

  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();

   const config = {
     headers: {
       authorization: `Bearer ${localStorage.getItem("token_id")}`,
     },
   };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!editMode) {
      await axios
        .post(
          `${apiUrl}/configs`,
          {
            first_referral_percentage: form.first_referral_percentage,
            user_referral_percentage: form.user_referral_percentage,
            created_by: 1,
          },
          config
        )
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
            // navigate("/homepage");
            navigate("/homepage/configs")
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    } else {
      await axios
        .put(`${apiUrl}/configs/${id}`,  {
          first_referral_percentage: form.first_referral_percentage,
          user_referral_percentage: form.user_referral_percentage,
          created_by: 1,
        },config)
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
            // navigate("/user");
          navigate("/homepage/configs")} else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    }
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setForm({ ...initialstate });
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/configs/${id}`,config);
    console.log(formData.data.data);
    if (formData.status === 200) {
      setForm({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  return (
    <div
    >
      <div className="flex flex-rows justify-center items-center w-full  py-8 border-b-2 border-gray-300">
        <h2 className="text-xl md:text-2xl  font-semibold text-globalBlack w-full">
          Form
        </h2>
        <Link to="/homepage/configs">
          <FaTimes className="w-12 h-6 cursor-pointer text-gray-500" />
        </Link>
      </div>
      
      <form onSubmit={handleSubmit}>
        <div className="w-full h-full grid grid-cols-1  gap-10">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 pt-6">
            <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  Name
                </label>
              </div>
              <div className="grid grid-cols-1">
                <input
                  type="text"
                  required
                  name="name"
                  value={form.first_referral_percentage}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      first_referral_percentage: e.target.value,
                    })
                  }
                  placeholder="Bobert"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                />
              </div>
            </div>
            <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  Referral Percentage
                </label>
              </div>
              <input
                required
                value={form.user_referral_percentage}
                onChange={(e) =>
                  setForm({ ...form, user_referral_percentage: e.target.value })
                }
                placeholder="100%"
                type="number"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        "
              />
            </div>
          </div>

          <div className="flex flex-row justify-center items-center my-16">
            <button
              className="py-2 px-7 bg-primary text-white shadow shadow-primary hover:bg-secondary ease-in-out duration-300 text-normal font-semibold
      rounded-md "
              type="submit"
            >
              {editMode ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ConfigsForm;
