import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "antd";
import OrdersEmail from "./OrdersEmail";
import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_API_URL;

const OrderViewDetails = () => {
  const { id, user_id } = useParams();

  const navigate = useNavigate();

  const assessmentid = 1;

  // const { getMemberId } = useContext(AppContext);

  const [customerDetails, setCustomerDetails] = useState([]);

  const [userDetails, setUserDetails] = useState([]);

  const [getPastOrder, setGetPastOrder] = useState(0);

  const [orderItem, setOrderItem] = useState([]);

  const [getAnswers, setGetAnswers] = useState([]);

  const [getQuestions, setGetQuestions] = useState([]);

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  // giving name for status of order

  const getStatus = (statusValue) => {
    switch (statusValue) {
      case 0:
        return "Rejected";

      case 2:
        return "Approved";

      case 3:
        return "Ready for dispatch";

      default:
        return "New";
    }
  };

  //Notes code here...

  const [notes, setNotes] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [handleNote, setHandleNote] = useState(false);

  const showNoteModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    await axios
      .put(
        `${apiUrl}/orders/${id}`,
        {
          notes: notes,
        },
        config
      )
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success("Notes updated", {
            position: toast.POSITION.TOP_RIGHT,
            className: "fixed top-14 right-1 z-30",
          });
        } else {
          toast.error(res.data.message);
        }
      });
    setIsModalVisible(false);
    setHandleNote(!handleNote);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  //orders email code here...

  const [showDialog, setShowDialog] = useState(false);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  //Tracking id code here...

  const [trackingModalIsOpen, setTrackingModalIsOpen] = useState(false);

  const [trackingId, setTrackingId] = useState("");

  const [handleTrackingId, setHandleTrackingId] = useState(false);

  function closeModal3() {
    setTrackingModalIsOpen(false);
  }

  function closeTrackingModal() {
    setTrackingModalIsOpen(false);
  }

  function openTrackingModal() {
    setTrackingModalIsOpen(true);
  }

  const handleTrackingIdUpdate = async () => {
    await axios
      .put(
        `${apiUrl}/trackingId/${id}`,
        {
          trackingId: trackingId,
        },
        config
      )
      .then((res) => {
        //  console.log(res.status);
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "fixed top-14 right-1",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "fixed top-14 right-1",
          });
        }
      });
    setHandleTrackingId(!handleTrackingId);
    closeTrackingModal();
  };

  // handle send the approve order, notes and precriber notes

  const getData = localStorage.getItem("weightloss_role");

  const [getPrecriberFee, setGetPrecriberFee] = useState("");

  const [getFeeEarned, setGetFeeEraned] = useState("");

  const [reason, setReason] = useState({
    reason: "",
    reason_2: "",
  });

  const [handleReason, setHandleReason] = useState(false);

  const [priscriberNotes, setPriscriberNotes] = useState([]);

  const handleSend = async () => {
    if (getData === "4") {
      await axios
        .put(
          `${apiUrl}/orderstatus/${id}`,
          {
            status: 3,
            approve_reason: reason.reason,
            priscriberNotes: priscriberNotes,
            staff_id: localStorage.getItem("weightloss_code"),
          },
          config
        )
        .then((res) => {
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
            navigate(`/homepage/dashboard`);
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    } else {
      await axios
        .put(
          `${apiUrl}/orderstatus/${id}`,
          {
            status: 2,
            approve_reason: reason.reason,
            priscriberNotes: priscriberNotes,
            staff_id: localStorage.getItem("weightloss_code"),
          },
          config
        )
        .then((res) => {
          //  console.log(res.status);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
            navigate(`/homepage/dashboard`);
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    }

    if (getData === "2") {
      axios
        .put(
          `${apiUrl}/doctors/fee/${localStorage.getItem("weightloss_user_id")}`,
          {
            fee_earned: +parseInt(getFeeEarned) + +parseInt(getPrecriberFee),
          },
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem("token_id")}`,
            },
          }
        )
        .then((res) => {
          // setImg(res.data.data);
          console.log(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setHandleReason(!handleReason);
    closeModal();
  };

  //to get fee earned data

  useEffect(() => {
    axios
      .get(
        `${apiUrl}/doctors/${localStorage.getItem("weightloss_user_id")}`,
        config
      )
      .then((res) => {
        console.log(res.data.data[0].fee_earned);
        setGetFeeEraned(res.data.data[0].fee_earned);
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, [localStorage.getItem("weightloss_user_id")]);

  //to get the dynamic fee detail

  useEffect(() => {
    axios
      .get(`${apiUrl}/fee_details`, config)
      .then((res) => {
        console.log(res.data.data[0].prescriber_percentage);
        setGetPrecriberFee(res.data.data[0].prescriber_percentage);
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, []);

  //modal for confirm approve order

  const [modalIsOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
    console.log("test");
  }

  const { confirm } = Modal;

  const approveStatus = () => {
    confirm({
      title: "Are you sure to approve this order?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        openModal();
      },
      onCancel() {
        console.log("Cancel");
      },
      cancelButtonProps: {
        style: { color: "white", backgroundColor: "#EF4444" },
      },
      okButtonProps: {
        style: { color: "white", backgroundColor: "#22C55E" },
      },
    });
  };

  const handleRefund = () => {
    confirm({
      title: "Are you sure to Refund this order?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        refundRequest();
      },
      onCancel() {
        console.log("Cancel");
      },
      cancelButtonProps: {
        style: { color: "white", backgroundColor: "#EF4444" },
      },
      okButtonProps: {
        style: { color: "white", backgroundColor: "#22C55E" },
      },
    });
  };

  const refundRequest = async () => {
    try {
      const formData = {
        order_id: id
      }
      await axios.post(`${apiUrl}/refundRequest`, formData, config).then((res) => {
        console.log(res);
        if (res.data.error === false) {
          const refundId = res.data.refund.id;
          const refundStatus = res.data.refund.status;
          const currentDate = new Date();
          const formdata = {
            refundstatus: refundStatus,
            refundid: refundId,
            refundDate: dayjs(currentDate).format('DD-MM-YYYY'),
            orderId: id
          }

          console.log(formdata, "RES")
          // axios.post(`${apiUrl}/updateRefund`, formdata, config).then((response) => {
          //   if (response.error === false) {
          //     toast.success(res.message, {
          //       position: toast.POSITION.TOP_CENTER,
          //     });
          //   }

          // });
        } else {
          console.log(res.data);
        }
      })
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  const [modalIsOpen2, setIsOpen2] = React.useState(false);

  function openModal2() {
    setIsOpen2(true);
  }

  function closeModal2() {
    setIsOpen2(false);
  }
  const handleSend2 = async (event) => {
    event.preventDefault();

    const timestamp = Date.now();
    const todayDate = dayjs(timestamp).format("YYYY-MM-DD HH:mm:ss");

    await axios
      .put(
        `${apiUrl}/orderstatus/${id}`,
        {
          status: 0,
          disapprove_reason: reason.reason_2,
          disapprove_date: todayDate
        },
        config
      )
      .then((res) => {
        //  console.log(res.status);
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "fixed top-14 right-1",
          });
          navigate(`/homepage/dashboard`);
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "fixed top-14 right-1",
          });
        }
      });
    setHandleReason(!handleReason);
    closeModal2();
  };

  const disapproveStatus = () => {
    confirm({
      title: "Are you sure to Reject this order?",
      icon: <ExclamationCircleFilled />,
      onOk() {
        openModal2();
      },
      onCancel() {
        console.log("Cancel");
      },
      cancelButtonProps: {
        style: { color: "white", backgroundColor: "#EF4444" },
      },
      okButtonProps: {
        style: { color: "white", backgroundColor: "#22C55E" },
      },
    });
  };

  //for prescrier notes

  const [getPrecriberNotes, setGetPrecriberNotes] = useState([]);

  const [notesModalIsOpen, setNotesModalIsOpen] = React.useState(false);

  function closeNotesModal() {
    setNotesModalIsOpen(false);
  }

  // for getting initial data

  useEffect(() => {
    axios
      .get(`${apiUrl}/getorderitems/${id}`)
      .then((res) => {
        console.log(res.data.data);
        if (res !== undefined) {
          setOrderItem(res.data.data);
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });

    axios
      .get(`${apiUrl}/getlastassessment/${assessmentid}`, config)
      .then((res) => {
        console.log(res.data.data);
        if (res !== undefined) {
          console.log(res);
          setGetAnswers(res.data.lastAssessmentAnswer);
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });

    axios
      .get(`${apiUrl}/orders/${id}`, config)
      .then((res) => {
        console.log(res.data.data);
        if (res !== undefined) {
          setCustomerDetails(res.data.data[0]);
          setNotes(res.data.data[0].notes);
          setTrackingId(res.data.data[0].trackingId);
          setReason({
            reason: res.data.data[0].approve_reason,
            reason_2: res.data.data[0].disapprove_reason,
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });

    axios
      .get(`${apiUrl}/prescriberNotes/${id}`, config)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data !== undefined) {
          setGetPrecriberNotes(res.data.data);
          console.log("prescriber", res.data.data);
        } else {
          setGetPrecriberFee("No Notes Found");
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });

    // getuserorders

    axios
      .get(`${apiUrl}/users/${user_id}`, config)
      .then((res) => {
        console.log(res.data.data);
        if (res !== undefined) {
          setUserDetails(res.data.data[0]);
          axios
            .post(`${apiUrl}/getuserorders`, { user_id: user_id })
            .then((res) => {
              if (res.data.status === 200) {
                setGetPastOrder(res.data.data.length);
              }
            });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, [id, handleNote, handleTrackingId, handleReason]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log("dwdw", orderItem);

  //custom css for ant design button in modal

  const cancelButtonProps = {
    style: { color: "white", backgroundColor: "#EF4444" },
  };

  const okButtonProps = {
    style: { color: "white", backgroundColor: "#22C55E" },
  };

  const handleCustomer = () => {
    navigate(`/homepage/user/userdetailpage/${userDetails?.id}`);
  };

  return (
    <>
      <div className="w-full h-fit flex flex-col gap-5">
        <div className="w-full flex flex-row justify-center items-center gap-5">
          <div className="w-2/3 h-fit flex flex-col gap-5">
            <div className="border border-gray-100 drop-shadow w-full h-[100px] p-4 flex flex-row justify-center items-center rounded bg-white">
              <div className="w-1/2 flex flex-col justify-center gap-1.5">
                <span className="text-[15px] text-gray-600 font-medium capitalize flex flex-row gap-1">
                  Order ID :{" "}
                  <p className="text-gray-600">{customerDetails?.id}</p>
                </span>
                {/* <span className="text-[16px] text-gray-600 font-medium capitalize">
                  Product Name : {customerDetails?.productname}
                </span> */}
                <span className="text-[15px] text-gray-600 font-medium capitalize">
                  Order Date :{" "}
                  {dayjs(customerDetails?.created_at).format("DD-MM-YYYY")}
                </span>
              </div>
              <div className="w-1/2 flex flex-col justify-center gap-1.5">
                <span className="text-[15px] text-gray-600 font-medium capitalize">
                  Order Status : {getStatus(customerDetails?.status)}
                </span>
                {/* <span className="text-[16px] text-gray-600 font-medium capitalize">
                  Quantity : 1
                </span> */}
                <span className="text-[15px] text-gray-600 font-medium capitalize">
                  Order Value : &#163;
                  {parseFloat(customerDetails?.total).toFixed(2)}
                </span>
              </div>
            </div>
            <div
              className="border border-gray-100 drop-shadow bg-white w-full h-[740px]  rounded flex flex-col p-4 gap-4 overflow-y-scroll"
              style={{ scrollbarWidth: "none" }}
            >
              <h1 className="text-gray-700 font-bold text-lg">
                Consultation Form Response :
              </h1>
              {orderItem.map((d) => (
                <div
                  className="w-full border border-gray-300 flex flex-col gap-5 p-4 rounded overflow-y-scroll h-[720px]"
                  style={{ scrollbarWidth: "none" }}
                >
                  <div className="flex flex-row justify-between">
                    <div className="flex flex-col  gap-4">
                      <h1 className="text-gray-700 font-bold text-lg">
                        Traveller details :
                      </h1>
                      <h1 className="text-[15px] text-gray-600 font-medium capitalize">
                        Name : {d?.traveller_name}
                      </h1>
                      <h1 className="text-[15px] text-gray-600 font-medium capitalize">
                        Age : {d?.traveller_age}
                      </h1>
                      <h1 className="text-[15px] text-gray-600 font-medium capitalize">
                        Duration of Trip : {d?.traveller_durationof_trip}
                      </h1>
                      <h1 className="text-[15px] text-gray-600 font-medium capitalize">
                        Gender : {d?.traveller_gender}
                      </h1>
                      {d?.traveller_weight && (
                        <h1 className="text-[15px] text-gray-600 font-medium capitalize">
                          Weight : {d?.traveller_weight}
                        </h1>
                      )}
                    </div>
                    <div className="flex flex-col  gap-4">
                      <h1 className="text-gray-700 font-bold text-lg">
                        Treatment details :
                      </h1>

                      <h1 className="text-[15px] text-gray-600 font-medium capitalize">
                        Medicine Name : {d?.medicine_name}
                      </h1>
                      <h1 className="text-[15px] text-gray-600 font-medium capitalize">
                        Medicine price : &#163;
                        {parseFloat(d?.medicine_price).toFixed(2)}
                      </h1>
                      <h1 className="text-[15px] text-gray-600 font-medium capitalize">
                        Quantity : {d?.quantity}
                      </h1>
                    </div>
                  </div>

                  <h1 className="text-gray-700 font-bold text-lg">
                    Assessment details :
                  </h1>

                  {d?.chat_response?.map((item, index) => (
                    <div key={index}>
                      {item.sender === "bot" && (
                        <h1 className="text-[15px] text-gray-600 font-medium capitalize">
                          bot: {item.text}
                        </h1>
                      )}
                      {item.sender === "user" && (
                        <h1 className="text-[15px] text-gray-600 font-medium capitalize">
                          user: {item.text}
                        </h1>
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div className="w-1/3 h-fit gap-5 flex flex-col">
            <div
              className="border border-gray-100 drop-shadow bg-white w-full h-[200px] p-4 rounded cursor-pointer"
              onClick={handleCustomer}
            >
              <div className="w-full h-full flex flex-col justify-start gap-1.5">
                <h1 className="text-gray-700 text-lg font-bold mb-1">
                  Customer Details :
                </h1>
                <span className="text-[15px] text-gray-600 font-medium capitalize">
                  Name : {userDetails?.first_name} {userDetails?.last_name}
                </span>
                <span className="text-[15px] text-gray-600 font-medium capitalize">
                  No. of Past Orders : {getPastOrder ? getPastOrder : 0}
                </span>
                <span className="text-[15px] text-gray-600 font-medium capitalize">
                  Mobile No : {userDetails?.phoneno}
                </span>
                <span className="text-[15px] text-gray-600 font-medium ">
                  Email ID : {userDetails?.email}
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-2 border border-gray-100 drop-shadow bg-white w-full h-[200px] p-4 rounded">
              <span className="text-gray-700 text-lg font-bold ">
                Shipping Address :
              </span>
              <span className="text-[15px] text-gray-600 font-medium capitalize">
                {customerDetails?.delivery_address}
              </span>
            </div>
            <div className="border border-gray-100 drop-shadow bg-white w-full h-[420px] p-6 rounded">
              <div className="w-full h-full flex flex-col justify-between">
                <div className="w-full h-full flex flex-col gap-2">
                  <span className="text-gray-700 text-lg font-bold ">
                    Notes :
                  </span>
                  <span
                    className="w-full h-[280px] border-2 hover:border-gray-300 rounded overflow-y-scroll p-4"
                    style={{ scrollbarWidth: "none" }}
                  >
                    {customerDetails?.notes}
                  </span>
                </div>
                <div className="w-full flex justify-center">
                  <span
                    className="py-2 px-8 text-white font-medium bg-zinc-500 rounded cursor-pointer 
                  shadow shadow-zinc-300 hover:bg-zinc-600 duration-300"
                    onClick={showNoteModal}
                  >
                    Add Notes
                  </span>
                  {/* modal for add notes for customer order detail */}
                  <Modal
                    title="Notes"
                    open={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    cancelButtonProps={cancelButtonProps}
                    okButtonProps={okButtonProps}
                  >
                    <textarea
                      autoFocus
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      placeholder="Enter your notes here..."
                      className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-[140px] w-full
                      text-md text-gray-700 focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
                      "
                    />
                  </Modal>
                </div>
              </div>
            </div>
            {/* <div className="border border-gray-100 drop-shadow bg-white w-full h-[200px] p-4 rounded">
              <span className="text-gray-700 text-md font-bold ">
                Order Logs:
              </span>
            </div> */}
          </div>
        </div>

        <div className="w-full flex flex-col justify-center items-center h-fit gap-5 py-8">
          <div className="w-full flex flex-row justify-center items-center gap-5">
            {/* <span className="py-1.5 px-8 text-white font-medium bg-zinc-500 rounded cursor-pointer shadow shadow-zinc-300 hover:bg-zinc-600 duration-300">
              Print Consultation Form
            </span> */}
            <Link to={`/homepage/dashboard/document/${id}/${user_id}`}>
              <span className="py-2 px-10 text-white font-medium bg-zinc-500 rounded cursor-pointer shadow shadow-zinc-300 hover:bg-zinc-600 duration-300">
                Print Prescription
              </span>
            </Link>
            {getData === "1" && (
              <>
                <Link
                  to={`/homepage/dashboard/dispenserlabel/${id}/${user_id}`}
                >
                  <span className="py-2 px-10 text-white font-medium bg-zinc-500 rounded cursor-pointer shadow shadow-zinc-300 hover:bg-zinc-600 duration-300">
                    Print Dispensing Label
                  </span>
                </Link>
              </>
            )}

            {getData === "4" && (
              <>
                <Link
                  to={`/homepage/dashboard/dispenserlabel/${id}/${user_id}`}
                >
                  <span className="py-2 px-10 text-white font-medium bg-zinc-500 rounded cursor-pointer shadow shadow-zinc-300 hover:bg-zinc-600 duration-300">
                    Print Dispensing Label
                  </span>
                </Link>
              </>
            )}

            <span
              className="py-1.5 px-11 text-white font-medium bg-zinc-500 rounded cursor-pointer shadow shadow-zinc-300 hover:bg-zinc-600 duration-300"
              onClick={() => openDialog(id)}
            >
              Send Message
            </span>

            {getData === "1" && (
              <>
                <Link
                  to={`/homepage/dashboard/prescribernotes/${id}/${assessmentid}`}
                >
                  <span className="py-2 px-8 text-white font-medium bg-zinc-500 rounded cursor-pointer shadow shadow-zinc-300 hover:bg-zinc-600 duration-300">
                    Prescriber Notes
                  </span>
                </Link>
              </>
            )}

            {getData === "2" && (
              <>
                <Link
                  to={`/homepage/dashboard/prescribernotes/${id}/${assessmentid}`}
                >
                  <span className="py-2 px-8 text-white font-medium bg-zinc-500 rounded cursor-pointer shadow shadow-zinc-300 hover:bg-zinc-600 duration-300">
                    Prescriber Notes
                  </span>
                </Link>
              </>
            )}
          </div>
          <div className="w-full flex flex-row justify-center items-center gap-5">
            {getData !== "4" && (
              <>
                {" "}
                {customerDetails?.status === 1 && (
                  <span
                    className="py-2 px-8 text-white font-medium bg-green-500 hover:bg-green-600 duration-300 shadow shadow-green-300 hover:shadow-green-600 rounded cursor-pointer"
                    onClick={() => approveStatus()}
                  >
                    Approve Order
                  </span>
                )}
                {customerDetails?.status === 0 && (
                  <span
                    className="py-1.5 px-8 text-white font-medium bg-green-500 hover:bg-green-600 duration-300 shadow shadow-green-300 hover:shadow-green-600 rounded cursor-pointer"
                    onClick={() => approveStatus()}
                  >
                    Approve Order
                  </span>
                )}
              </>
            )}

            {getData !== "4" && (
              <>
                {" "}
                {customerDetails?.status !== 0 && (
                  <span
                    className="py-2 px-8 text-white font-medium bg-red-500 hover:bg-red-600 duration-300 shadow shadow-red-300 hover:shadow-red-600 rounded cursor-pointer"
                    onClick={() => disapproveStatus()}
                  >
                    Reject Order
                  </span>
                )}
              </>
            )}

            {getData === "1" && (
              <>
                {" "}
                <span
                  className="py-2 px-8 text-white font-medium bg-blue-500 hover:bg-blue-600 duration-300 shadow shadow-blue-300 hover:shadow-blue-600 rounded cursor-pointer"
                  onClick={() => handleRefund()}
                >
                  Refund
                </span>
              </>
            )}

            {getData === "1" && (
              <>
                {" "}
                <span
                  className="py-2 px-8 text-white font-medium bg-zinc-500 rounded cursor-pointer shadow shadow-zinc-300 hover:bg-zinc-600 duration-300"
                  onClick={() => openTrackingModal()}
                >
                  Add Tracking No
                </span>
              </>
            )}

            {getData === "4" && (
              <>
                {" "}
                <span
                  className="py-2 px-8 text-white font-medium bg-zinc-500 rounded cursor-pointer shadow shadow-zinc-300 hover:bg-zinc-600 duration-300"
                  onClick={() => openTrackingModal()}
                >
                  Add Tracking No
                </span>
              </>
            )}

            <Link to={`/homepage/dashboard`}>
              <button className="py-2 px-8 text-white font-medium bg-zinc-500 rounded cursor-pointer shadow shadow-zinc-300 hover:bg-zinc-600 duration-300">
                Go Back
              </button>
            </Link>
            {/* modal for add tracking no  */}
            <Modal
              title="Add Tracking No"
              open={trackingModalIsOpen}
              onOk={handleTrackingIdUpdate}
              onCancel={closeModal3}
              cancelButtonProps={cancelButtonProps}
              okButtonProps={okButtonProps}
            >
              <textarea
                required
                autoFocus
                value={trackingId}
                onChange={(e) => setTrackingId(e.target.value)}
                placeholder="Enter the tacking number..."
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-[80px] w-full
                text-md text-gray-700 focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
                "
              />
            </Modal>
            {/* modal for approve notes and prescriber notes */}
            <Modal
              open={modalIsOpen}
              onOk={handleSend}
              onCancel={closeModal}
              cancelButtonProps={cancelButtonProps}
              okButtonProps={okButtonProps}
            >
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                  <label className="text-lg font-medium text-gray-600">
                    Approved Notes
                  </label>
                  <textarea
                    type="text"
                    className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-[130px]
        text-md text-gray-600 focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        "
                    required
                    value={reason.reason}
                    onChange={(e) =>
                      setReason({
                        ...reason,
                        reason: e.target.value,
                      })
                    }
                    placeholder="Enter your notes here..."
                    autoFocus
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <label className="text-lg font-medium text-gray-600">
                    Prescriber Notes
                  </label>
                  <textarea
                    type="text"
                    className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-[130px]
        text-md text-gray-600 focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        "
                    required
                    value={priscriberNotes}
                    onChange={(e) => setPriscriberNotes(e.target.value)}
                    placeholder="Enter your notes here..."
                  />
                </div>
              </div>
            </Modal>
            {/* modal for reject the order  */}
            <Modal
              open={modalIsOpen2}
              onOk={handleSend2}
              onCancel={closeModal2}
              cancelButtonProps={cancelButtonProps}
              okButtonProps={okButtonProps}
            >
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-4">
                  <label className="text-lg font-medium text-gray-600">
                    Rejected Reason
                  </label>
                  <textarea
                    type="text"
                    className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-[130px]
        text-md text-gray-600 focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        "
                    required
                    value={reason.reason_2}
                    onChange={(e) =>
                      setReason({ ...reason, reason_2: e.target.value })
                    }
                    placeholder="Enter your Reason..."
                  />
                </div>
              </div>
            </Modal>
            <Modal
              style={{ width: "400px", height: "400px", overflowY: "scroll" }}
              open={notesModalIsOpen}
              onCancel={closeNotesModal}
              cancelButtonProps={{ style: { display: "none" } }}
              okButtonProps={{ style: { display: "none" } }}
            >
              <VerticalTimeline>
                {getPrecriberNotes.length === 0 ? (
                  <p className="text-gray-700 text-lg font-bold ">
                    No Notes found.
                  </p>
                ) : (
                  getPrecriberNotes.map((event) => (
                    <VerticalTimelineElement
                      key={event.id}
                      className="vertical-timeline-element--work"
                      date={event.notes_date}
                      iconStyle={{
                        background: "rgb(33, 150, 243)",
                        color: "#fff",
                      }}
                    >
                      <p>{event.notes}</p>
                    </VerticalTimelineElement>
                  ))
                )}
              </VerticalTimeline>
            </Modal>
          </div>
        </div>
      </div>
      <OrdersEmail isOpen={showDialog} onClose={closeDialog} id={id} />
    </>
  );
};

export default OrderViewDetails;
