import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import { toast} from "react-toastify";
import { useParams, useNavigate } from "react-router";

const apiUrl = process.env.REACT_APP_API_URL;

const DoctorsForm = () => {
  
  const initialstate = {
    first_name: "",
    surname: "",
    email: "",
    phoneno: "",
    password: "",
    role: "",
    code: "",
    sign_image: "",
    description: "",
    image: "",
    registration_no: "",
    address_line1: "",
    address_line2: "",
    city: "",
    postcode: "",
  };

  const [form, setForm] = useState(initialstate);

  console.log(form.password);

  const [cmpName, setCmpName] = useState([]);

  const [editMode, setEditMode] = useState(false);

  const [signImage, setSignImage] = useState(null);

  const [profile, setProfile] = useState(null);

  const navigate = useNavigate();

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const [randomId, setRandomId] = useState([]);

  // console.log("id", randomId);

  useEffect(() => {
    axios
      .get(`${apiUrl}/roles`, config)
      .then((res) => {
        console.log(res.data.data);
        setCmpName(res.data.data);
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, []);

  useEffect(() => {
    const getRandomString = (length = 10) => {
      let randomChars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let result = "";
      for (let i = 0; i < length; i++) {
        result += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }
      return result;
    };
    setRandomId(getRandomString());
  }, []);

  const onUploadSignImage = (event) => {
    setSignImage(event.target.files[0]);
  };

  const onUploadProfile = (event) => {
    setProfile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("first_name", form.first_name);
    formData.append("surname", form.surname);
    formData.append("email", form.email);
    formData.append("phoneno", form.phoneno);
    formData.append("password", form.password);
    formData.append("role", form.role);
    formData.append("code", randomId);
    formData.append("registration_no", form.registration_no);
    formData.append("address_line1", form.address_line1);
    formData.append("address_line2", form.address_line2);
    formData.append("city", form.city);
    formData.append("postcode", form.postcode);

    //  for (let i = 0; i < profile.length; i++) {
    //    formData.append("image", profile[i]);
    //  }

    formData.append("image", profile);
    formData.append("sign_image", signImage);
    formData.append("description", form.description);
    formData.append("created_by", 1);

    if (form.role === "none") {
      toast.warn("Role required*", {
        position: toast.POSITION.TOP_RIGHT,
        className: "fixed top-14 right-1",
      });
    } else if (!editMode) {
      await axios.post(`${apiUrl}/doctors`, formData, config).then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "fixed top-14 right-1",
          });navigate("/homepage/staffs")
        } else if (res.data.status === 301) {
          toast.warn(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "fixed top-14 right-1",
          });
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT,
            className: "fixed top-14 right-1",
          });
        }
      });
    } else {
      await axios
        .put(`${apiUrl}/doctors/${id}`, formData, config)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
              
            });
          navigate('/homepage/staffs')} 
          else if (res.data.status === 301) {
            toast.warn(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    }
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setForm({ ...initialstate });
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/doctors/${id}`, config);

    console.log(formData.data.data);

    if (formData.status === 200) {
      setForm({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  useEffect(() => {
    if (form.role === "none") {
      toast.warn("Select any other Role", {
        position: toast.POSITION.TOP_RIGHT,
        className: "fixed top-14 right-1",
      });
    }
  }, [form.role]);

  return (
    <div>
      <div className="flex flex-col justify-center items-center w-full  h-fit">
        <div className="flex flex-rows justify-center items-center w-full py-8 border-b-2 border-gray-300">
          <h2 className="text-xl md:text-2xl  font-semibold text-globalBlack w-full">
            Members Form
          </h2>
          <Link to="/homepage/staffs">
            <FaTimes
              className="w-12 h-6 cursor-pointer text-gray-500"
              // onClick={() => setToggle(!toggle)}
            />
          </Link>
        </div>

      </div>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="w-full h-fit grid grid-cols-1 gap-2 pt-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10 my-5">
            <div className="h-20 grid grid-cols-1  items-center">
              <div>
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-globalBlack">
                    {" "}
                    First Name{" "}
                  </span>
                  <span className="text-red-500 text-3xl font-medium">*</span>
                </label>
              </div>
              <div className="grid grid-cols-1">
                <input
                  type="text"
                  required
                  name="firstname"
                  value={form.first_name}
                  onChange={(e) =>
                    setForm({ ...form, first_name: e.target.value })
                  }
                  placeholder="Bobert"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-700 focus:border-primary focus:bg-white focus:text-gray-700 focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                />
              </div>
            </div>
            <div className="h-20 grid grid-cols-1 items-center">
              <div>
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-globalBlack">
                    {" "}
                    Surname{" "}
                  </span>
                  <span className="text-red-500 text-3xl font-medium">*</span>
                </label>
              </div>
              <div className="grid grid-cols-1">
                <input
                  type="text"
                  name="surname"
                  value={form.surname}
                  onChange={(e) =>
                    setForm({ ...form, surname: e.target.value })
                  }
                  placeholder="Gomez"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-700 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                />
              </div>
            </div>
            <div className="h-20 grid grid-cols-1 items-center">
              <div>
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-globalBlack">
                    {" "}
                    Password{" "}
                  </span>
                  <span className="text-red-500 text-3xl font-medium">*</span>
                </label>
              </div>
              <div className="grid grid-cols-1">
                <input
                  type="password"
                  required
                  name="password"
                  // value={form.password}
                  onChange={(e) =>
                    setForm({ ...form, password: e.target.value })
                  }
                  placeholder="************"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded bg-white
          text-md text-gray-700 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none"
                />
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10 my-5">
            <div className="h-20 grid grid-cols-1 items-center">
              <div>
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-globalBlack">
                    {" "}
                    Email Address{" "}
                  </span>
                  <span className="text-red-500 text-3xl font-medium">*</span>
                </label>
              </div>
              <input
                required
                name="email"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
                placeholder="vicky@gmail.com"
                type="email"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        "
              />
            </div>
            <div className="h-20 grid grid-cols-1 items-center">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  Please Upload Your Profile
                </label>
              

              </div>
              <input
                name="image"
                multiple
                // value={form.image}
                onChange={onUploadProfile}
                className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-400 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-primary file:px-3 file:py-1.5 file:text-white file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-secondary focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none"
                type="file"
              />
            </div>
            <div className="h-20 grid grid-cols-1 items-center">
              <div>
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-globalBlack">
                    {" "}
                    Signature Image{" "}
                  </span>
                  <span className="text-red-500 text-3xl font-medium">*</span>
                </label>
              </div>
              <input
                name="sign_image"
                multiple
                // value={form.image}
                onChange={onUploadSignImage}
                className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-400 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-primary file:px-3 file:py-1.5 file:text-white file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-secondary focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none "
                type="file"
              />
            </div>
          </div>
          <div className="grid grid-cols-3 items-center gap-10 my-2 ">
            <div className="h-20 grid grid-cols-1 items-center gap-2">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  Phone Number
                </label>
              </div>
              <input
                value={form.phoneno}
                name="phoneno"
                onChange={(e) => setForm({ ...form, phoneno: e.target.value })}
                placeholder="982 678 8982"
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>

            <div className="h-20 grid grid-cols-1 items-center">
              <div>
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-globalBlack">
                    {" "}
                    Roles{" "}
                  </span>
                  <span className="text-red-500 text-3xl font-medium">*</span>
                </label>
              </div>
              <div className="grid grid-cols-1">
                <select
                  name="role"
                  required
                  value={form.role}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      role: e.target.value,
                    })
                  }
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
          text-lg text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none"
                >
                  <option
                    value="none"
                    className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
          text-md text-gray-400"
                  >
                    Select - Below
                  </option>

                  {cmpName.map((role) => {
                    return (
                      <React.Fragment key={role.id}>
                        <option
                          value={role.id}
                          className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
          text-lg text-gray-400"
                        >
                          {role.name}
                        </option>
                      </React.Fragment>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="h-20 grid grid-cols-1 items-center">
              <label className="flex flex-row gap-1">
                <span className="text-lg font-medium text-globalBlack">
                  {" "}
                  Registration No{" "}
                </span>
                {form.role !== "4" && (
                  <span className="text-red-500 text-3xl font-medium">*</span>
                )}
              </label>
              <input
                value={form.registration_no}
                required={form.role === "4" ? false : true}
                name="phoneno"
                onChange={(e) =>
                  setForm({ ...form, registration_no: e.target.value })
                }
                placeholder="Registration no"
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 my-2">
            <div className="h-fit grid grid-cols-1 items-center gap-2">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  Address Line 1
                </label>
              </div>
              <textarea
                name="address line 1"
                value={form.address_line1}
                onChange={(e) =>
                  setForm({ ...form, address_line1: e.target.value })
                }
                placeholder="address line 1"
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
            <div className="h-fit grid grid-cols-1 items-center gap-2">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  Address Line 2
                </label>
              </div>
              <textarea
                name="address line 2"
                value={form.address_line2}
                onChange={(e) =>
                  setForm({ ...form, address_line2: e.target.value })
                }
                placeholder="address line 2"
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="h-20 grid grid-cols-1 items-center gap-2">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  City
                </label>
              </div>
              <input
                value={form.city}
                name="city"
                onChange={(e) => setForm({ ...form, city: e.target.value })}
                placeholder="city"
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
            <div className="h-20 grid grid-cols-1 items-center gap-2">
              <div>
                <label className="text-lg font-medium text-globalBlack">
                  Postcode
                </label>
              </div>
              <input
                value={form.postcode}
                name="postcode"
                onChange={(e) => setForm({ ...form, postcode: e.target.value })}
                placeholder="600082"
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div>
          <div className="h-fit grid grid-cols-1 items-center gap-2">
            <div>
              <label className="text-lg font-medium text-globalBlack">
                Description
              </label>
            </div>
            <textarea
              name="description"
              value={form.description}
              onChange={(e) =>
                setForm({ ...form, description: e.target.value })
              }
              placeholder="Enter your Description..."
              type="text"
              className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
            />
          </div>

          <div className="flex flex-row justify-center items-center my-16 gap-6 ">
            <button
              className="py-2 px-7 bg-primary text-white shadow shadow-primary hover:bg-secondary ease-in-out duration-300 text-normal font-semibold
      rounded-md disabled:bg-gray-400 disabled:shadow-gray-400 disabled:cursor-not-allowed "
              type="submit"
              disabled={form.role === ""}
            >
              {editMode ? "Update" : "Submit"}
            </button>
          </div>
        </div>
       
      </form>
    </div>
  );
};

export default DoctorsForm;
