import React from "react";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FiEdit } from "react-icons/fi";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import axios from "axios";
import { toast } from "react-toastify";
import { Select } from "antd";
import { FaPlus } from "react-icons/fa";
import Loading from "../../subComponents/Loading";

const apiUrl = process.env.REACT_APP_API_URL;

const Staffs = () => {
  const [filteredData, setFilteredData] = useState([]);

  const [item, setItem] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const getData = localStorage.getItem("weightloss_role");

  const getRole = (role) => {
    console.log(role);
    switch (role) {
      case "1":
        return "Superadmin";
      case "2":
        return "Prescriber";
      case "4":
        return "Dispenser";
    }
  };

  const handleDelete = async (id) => {
    confirmAlert({
      title: "Are you sure",
      message: "You want to delete this data ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await axios
              .delete(`${apiUrl}/doctors/${id}`, config)
              .then((res) => {
                //  console.log(res.status);
                if (res.status === 200) {
                  setFilteredData((prevData) =>
                    prevData.filter((item) => item.id !== id)
                  );
                  toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: "fixed top-14 right-1",
                  });
                } else {
                  toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                    className: "fixed top-14 right-1",
                  });
                }
              });
          },
        },
        {
          label: "No",
          onClick: () => {
            toast.warn("cancelled", {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          },
        },
      ],
    });
  };

  const [status, setStatus] = useState(false);

  const handleToggle = async (id, value) => {
    const newStatus = value ? 0 : 1;
    try {
      await axios.put(
        `${apiUrl}/doctors/status/${id}`,
        { status: newStatus },
        config
      );
      toast.success(
        `Staff ${newStatus ? "activated" : "deactivated"} successfully`,
        {
          position: toast.POSITION.TOP_RIGHT,
          className: "fixed top-14 right-1",
        }
      );
      setStatus(!status); // Toggle status to trigger useEffect
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
        className: "fixed top-14 right-1",
      });
    }
  };

  const columns = [
    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[160px]">
          S.No
        </h1>
      ),
      selector: (row) => row.id,
      sortable: true,
      width: "160px",
    },
    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[220px]">Staff Name</h1>
      ),
      selector: (row) => row.first_name,
      sortable: true,
      width: "220px",
    },
    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[240px]">
          Mail ID
        </h1>
      ),
      selector: (row) => row.email,
      sortable: true,
      width: "240px",
    },
    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[200px]">
          Phone Number
        </h1>
      ),
      selector: (row) => row.phoneno,
      sortable: true,
      width: "200px",
    },
    {
      name: <h1 className="text-lg text-gray-950 font-semibold">Status</h1>,
      selector: (row) => (
        <input
          type="checkbox"
          className="toggle toggle-success w-[45px] h-[20px]"
          checked={row.status === 1}
          onChange={() => handleToggle(row.id, row.status === 1)}
        />
      ),
    },
    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[180px]">Role</h1>
      ),
      selector: (row) => getRole(row.role),
      width: "180px",
    },
    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[200px]">
          Actions
        </h1>
      ),
      cell: (row) => (
        <>
          <div className="flex flex-row items-center gap-3">
            {/* <FcViewDetails
              className="w-8 h-6 text-blue-500 cursor-pointer"
              onClick={() => navigate(`/homepage/staffs/viewDetails/${row.id}`)}
            /> */}
            <MdDelete
              onClick={() => handleDelete(row.id)}
              className="w-8 h-6 text-red-500 cursor-pointer"
            />
            <Link to={`/homepage/staffs/formedit/${row.id}`}>
              <FiEdit className="w-8 h-5 text-blue-500 cursor-pointer" />
            </Link>
          </div>
        </>
      ),
    },
  ];

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  const [pending, setPending] = useState(true);

  useEffect(() => {
    axios
      .get(`${apiUrl}/doctors`, config)
      .then((res) => {
        console.log(res.data.data);
        setItem(res.data.data);
        setPending(false);
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, [status]);

  useEffect(() => {
    const filterItem = (item) => {
      return Object.values(item).some((value) => {
        if (typeof value === "string") {
          const lowerCaseValue = value.toString().toLowerCase();
          return lowerCaseValue.includes(searchTerm.toLowerCase());
        } else if (value instanceof Date) {
          const dateValue = value.toISOString().toLowerCase();
          return dateValue.includes(searchTerm.toLowerCase());
        } else if (typeof value == "number") {
          const stringValue = value.toString().toLowerCase();
          return stringValue === searchTerm.toLowerCase();
        }
        return false;
      });
    };

    const filteredResult = item.filter(filterItem);

    setFilteredData(filteredResult);
  }, [item, searchTerm]);

  const navigate = useNavigate();

  const { id } = useParams();

  const customStyles = {
    header: {
      style: {
        textAlign: "center", // Center header text
      },
    },
    cells: {
      style: {
        textAlign: "center", // Center cell text
      },
    },
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        fontSize: "12px",
        paddingLeft: "40px", // override the cell padding for head cells
        // paddingRight: "20px",
        backgroundColor: "#FBF7ED",
        color: "#1F2937",
        fontWeight: "400",

        // border: '1px solid #ddd',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#fff9f3",

        // borderBottomColor: "#FFFFFF",
        // outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        paddingLeft: "40px",
        paddingRight: "40px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "15px",
        color: "#1F2937",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  const orders = [
    {
      id: 1,
      order: "All Staffs",
    },

    {
      id: 2,
      order: "Prescribers",
    },
    {
      id: 3,
      order: "Dispensers",
    },
  ];

  const onChange = (value) => {
    console.log(`selected ${value}`);

    if (value === 1) {
      axios
        .get(`${apiUrl}/doctors`, config)
        .then((res) => {
          console.log(res.data.data);
          let data = res.data.data.length >= 1 ? res.data.data : []
          setItem(data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else if (value === 2) {
      axios
        .get(`${apiUrl}/getdoctors`, config)
        .then((res) => {
          console.log(res.data.data);
          let data = res.data.data.length >= 1 ? res.data.data : []
          setItem(data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    } else if (value === 3) {
      axios
        .get(`${apiUrl}/getdispencers`, config)
        .then((res) => {
          console.log(res.data.data);
          let data = res.data.data.length >= 1 ? res.data.data : []
          setItem(data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const options = orders.map((order) => ({
    value: order.id, // Set a unique value for each course
    label: order.order, // Display name for the course
  }));

  const handleRowClick = (row) => {
    // navigate("/homepage/viewDetails/new/" + row.id + "/" + row.assesment_id);
    navigate(`/homepage/staffs/viewDetails/${row.id}`);
  };

  return (
    <>
      {pending ? (
        <Loading />
      ) : (
        <div>
          <div className="flex flex-row justify-between w-full h-16 items-start gap-1">
            <div>
              <h4 className="text-2xl md:text-3xl font-bold text-globalBlack">
                Staffs
              </h4>
            </div>
            <div>
              <Link to="/homepage/staffs/addform">
                <button
                  className="py-1.5 px-4 md:py-2 md:px-7 text-md md:text-lg bg-primary text-white shadow shadow-primary hover:shadow-primary hover:bg-secondary ease-in-out duration-300 rounded-md md:rounded font-semibold flex flex-row 
          items-center gap-1"
                >
                  <FaPlus />
                  Add
                </button>
              </Link>
            </div>
          </div>
          <div className="w-full h-fit px-5 pt-5 flex flex-col bg-white border drop-shadow rounded">
            <div className="w-full h-12 flex flex-row items-center gap-10">
              <input
                placeholder="search..."
                type="text"
                required
                className="p-2 text-md border border-gray-400 
        rounded focus:border-primary focus:bg-white focus:text-black focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none"
                onChange={(event) => setSearchTerm(event.target.value)}
                value={searchTerm}
              />

              <Select
                showSearch
                placeholder="Select a order type"
                optionFilterProp="children"
                onChange={onChange}
                // onSearch={onSearch}
                filterOption={filterOption}
                options={options}
                className="w-[220px] h-[43px] rounded"
              />
            </div>
            <div className="mt-8">
              <DataTable
                fixedHeader={true}
                columns={columns}
                data={filteredData}
                pagination
                customStyles={customStyles}
                highlightOnHover
                pointerOnHover
                fixedHeaderScrollHeight="358px"
                onRowClicked={handleRowClick}
              />
            </div>
          </div>
        </div>
      )}
     
    </>
  );
};

export default Staffs;
