import React from "react";
import { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { toast} from "react-toastify";
import { useParams, useNavigate } from "react-router";

const apiUrl = process.env.REACT_APP_API_URL;

const ProductForm = ({ page, setPage }) => {
  const initialstate = {
    title: "",
    regularprice: "",
    saleprice: "",
    image: "",
    shortdescription: "",
    description: "",
    prescription: "",
  };

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
      "Content-Type": "multipart/form-data",
    },
  };

  const FormTiles = ["Basic Details Form", "FAQ Form"];

  const [form, setForm] = useState(initialstate);

  const [editMode, setEditMode] = useState(false);

  const [imageFile, setImageFile] = useState(null);

  const navigate = useNavigate();

  const onUploadImage = (event) => {
    setImageFile(event.target.files[0]);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("title", form.title);
    formData.append("regularprice", form.regularprice);
    formData.append("saleprice", form.saleprice);
    formData.append("shortdescription", form.shortdescription);
    formData.append("description", form.description);
    formData.append("prescription", form.prescription);
    formData.append("image", imageFile);
    formData.append("created_by", 1);

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    if (!editMode) {
      await axios
        .post(`${apiUrl}/products/basic_details`, formData, config)
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            setPage((currPage) => currPage + 1);
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    } else {
      await axios
        .put(`${apiUrl}/products/basic_details/${id}`, formData, config)
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            })
            navigate("/homepage/products")}
           else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    }
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setForm({ ...initialstate });
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(
      `${apiUrl}/products/basic_details/${id}`,
      config
    );
    //  console.log(formData.data.data.fullname);
    if (formData.status === 200) {
      setForm({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      {!editMode ? (
        <>
          <div className="grid grid-cols-1 gap-10">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-globalBlack">
                      {" "}
                      Medicine Name
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <div className="grid grid-cols-1">
                  <input
                    type="text"
                    required
                    name="title"
                    value={form.title}
                    onChange={(e) =>
                      setForm({ ...form, title: e.target.value })
                    }
                    placeholder="Enter the Medicine name..."
                    className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                  />
                </div>
              </div>
              <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-globalBlack">
                      {" "}
                      Regular price
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <input
                  required
                  value={form.regularprice}
                  onChange={(e) =>
                    setForm({ ...form, regularprice: e.target.value })
                  }
                  placeholder="Enter your regular price..."
                  type="text"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        "
                />
              </div>
              <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-globalBlack">
                      {" "}
                      Sale price
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <input
                  required
                  value={form.saleprice}
                  onChange={(e) =>
                    setForm({ ...form, saleprice: e.target.value })
                  }
                  placeholder="Enter your sale price..."
                  type="text"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        "
                />
              </div>
              <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-globalBlack">
                      {" "}
                      Please Upload Your Image
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <input
                  required
                  name="image"
                  multiple
                  // value={form.image}
                  onChange={onUploadImage}
                  className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-400 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-primary file:px-3 file:py-1.5 file:text-white file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-secondary focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
                  type="file"
                />
              </div>
            </div>

            <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
              <div>
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-globalBlack">
                    {" "}
                    Short Description
                  </span>
                  <span className="text-red-500 text-3xl font-medium">*</span>
                </label>
              </div>
              <textarea
                required
                value={form.shortdescription}
                onChange={(e) =>
                  setForm({ ...form, shortdescription: e.target.value })
                }
                placeholder="Enter your Description..."
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
            <div className="h-fit grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
              <div>
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-globalBlack">
                    {" "}
                    Description
                  </span>
                  <span className="text-red-500 text-3xl font-medium">*</span>
                </label>
              </div>
              <Editor
                apiKey="wdkecpbmr0iy7t2tatwvjpgi85s31if69utt174bhbvqv1ye"
                initialValue={form.description}
                init={{
                  branding: false,
                  height: 300,
                  menubar: true,
                  plugins:
                    " preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap  pagebreak nonbreaking anchor insertdatetime advlist lists wordcount",
                  toolbar:
                    "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                  image_advtab: true,
                }}
                onChange={(e) =>
                  setForm({ ...form, description: e.target.getContent() })
                }
              />
            </div>
            <div className="h-fit grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
              <div>
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-globalBlack">
                    {" "}
                    Prescription
                  </span>
                  <span className="text-red-500 text-3xl font-medium">*</span>
                </label>
              </div>
              <Editor
                apiKey="wdkecpbmr0iy7t2tatwvjpgi85s31if69utt174bhbvqv1ye"
                initialValue={form.prescription}
                init={{
                  branding: false,
                  height: 300,
                  menubar: true,
                  plugins:
                    " preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap  pagebreak nonbreaking anchor insertdatetime advlist lists wordcount",
                  toolbar:
                    "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                  image_advtab: true,
                }}
                onChange={(e) =>
                  setForm({ ...form, prescription: e.target.getContent() })
                }
              />
            </div>

            <div className="flex flex-row justify-center items-center mb-10">
              <button
                className="py-2 px-7 bg-primary text-white shadow shadow-primary hover:bg-secondary ease-in-out duration-300 text-normal font-semibold
                rounded-md disabled:cursor-not-allowed "
                disabled={form.description === "" && form.prescription === ""}
                type="submit"
              >
                {editMode ? "Update" : " Next"}
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div> 
            <div className="flex flex-row py-8 border-b-2 border-gray-300">
              <h1 className="text-2xl text-globalBlack font-semibold">Edit</h1>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-6">
              <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-globalBlack">
                      {" "}
                      Name
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <div className="grid grid-cols-1">
                  <input
                    type="text"
                    required
                    name="title"
                    value={form.title}
                    onChange={(e) =>
                      setForm({ ...form, title: e.target.value })
                    }
                    placeholder="Enter your name..."
                    className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
                  />
                </div>
              </div>
              <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-globalBlack">
                      {" "}
                      Regular price
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <input
                  required
                  value={form.regularprice}
                  onChange={(e) =>
                    setForm({ ...form, regularprice: e.target.value })
                  }
                  placeholder="Enter your regular price..."
                  type="text"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        "
                />
              </div>
              <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-globalBlack">
                      {" "}
                      Sale price
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <input
                  required
                  value={form.saleprice}
                  onChange={(e) =>
                    setForm({ ...form, saleprice: e.target.value })
                  }
                  placeholder="Enter your sale price..."
                  type="text"
                  className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
        "
                />
              </div>
              <div className="h-28 grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
                <div>
                  <label className="flex flex-row gap-1">
                    <span className="text-lg font-medium text-globalBlack">
                      {" "}
                      Please Upload Your Image
                    </span>
                    <span className="text-red-500 text-3xl font-medium">*</span>
                  </label>
                </div>
                <input
                  required
                  name="image"
                  multiple
                  // value={form.image}
                  onChange={onUploadImage}
                  className="relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-1.5 text-base font-normal text-gray-400 outline-none transition duration-300 ease-in-out file:-mx-3 file:-my-1.5 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-primary file:px-3 file:py-1.5 file:text-white file:transition file:duration-150 file:ease-in-out file:[margin-inline-end:0.75rem] file:[border-inline-end-width:1px] hover:file:bg-secondary focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
                  type="file"
                />
              </div>
            </div>

            <div className="h-fit grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
              <div>
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-globalBlack">
                    {" "}
                    Short Description
                  </span>
                  <span className="text-red-500 text-3xl font-medium">*</span>
                </label>
              </div>
              <textarea
                required
                value={form.shortdescription}
                onChange={(e) =>
                  setForm({ ...form, shortdescription: e.target.value })
                }
                placeholder="Enter your Description..."
                type="text"
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-24
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
            <div className="h-fit grid grid-cols-1 items-center p-3 gap-2 sm:mx-4 sm:my-16">
              <div>
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-globalBlack">
                    {" "}
                    Description
                  </span>
                  <span className="text-red-500 text-3xl font-medium">*</span>
                </label>
              </div>
              <Editor
                apiKey="wdkecpbmr0iy7t2tatwvjpgi85s31if69utt174bhbvqv1ye"
                initialValue={form.description}
                init={{
                  branding: false,
                  height: 300,
                  menubar: true,
                  plugins:
                    " preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap  pagebreak nonbreaking anchor insertdatetime advlist lists wordcount",
                  toolbar:
                    "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                  image_advtab: true,
                }}
                onChange={(e) =>
                  setForm({ ...form, description: e.target.getContent() })
                }
              />
            </div>
            <div className="h-fit grid grid-cols-1 items-center p-3 gap-2 sm:mx-4">
              <div>
                <label className="flex flex-row gap-1">
                  <span className="text-lg font-medium text-globalBlack">
                    {" "}
                    Prescription
                  </span>
                  <span className="text-red-500 text-3xl font-medium">*</span>
                </label>
              </div>
              <Editor
                apiKey="wdkecpbmr0iy7t2tatwvjpgi85s31if69utt174bhbvqv1ye"
                initialValue={form.prescription}
                init={{
                  branding: false,
                  height: 300,
                  menubar: true,
                  plugins:
                    " preview searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap  pagebreak nonbreaking anchor insertdatetime advlist lists wordcount",
                  toolbar:
                    "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
                  image_advtab: true,
                }}
                onChange={(e) =>
                  setForm({ ...form, prescription: e.target.getContent() })
                }
              />
            </div>

            <div className="flex flex-row justify-center items-center py-10">
              <button
                className="py-2 px-7 bg-primary text-white shadow shadow-primary hover:bg-secondary ease-in-out duration-300 text-normal font-semibold
                rounded-md disabled:cursor-not-allowed mb-10"
                disabled={form.description === "" && form.prescription === ""}
                type="submit"
  >
                {editMode ? "Update" : " Next"}
              </button>
            </div>
          </div>
        </>
      )}

     
    </form>
  );
};

export default ProductForm;
