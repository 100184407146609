import React from "react";
import DataTable from "react-data-table-component";
import { useState, useEffect } from "react";
import { FiDownload} from "react-icons/fi";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import axios from "axios";
import { toast} from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import { Modal } from "antd";
import CustomersEmail from "./CustomersEmail";
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_API_URL;

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const CustomersDetailPage = () => {
  const [filteredData, setFilteredData] = useState([]);

  const [item, setItem] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  const [mappedUsers, setMappedUsers] = useState([]);

  const [showViewDetails, setShowViewDetails] = useState(false);

  const [img, setImg] = useState(null);

  const [idProof, setIdProof] = useState(null);

  const { id } = useParams();

  const openDialog1 = (id) => {};

  const getStatus = (statusValue) => {
    switch (statusValue) {
      case 0:
        return "Rejected";

      case 2:
        return "Approved";

      case 3:
        return "Ready for dispatch";

      default:
        return "New";
    }
  };

  const columns = [
    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[150px]">
          Order ID
        </h1>
      ),
      selector: (row) => row.id,
      sortable: true,
      width: "150px",
    },
    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[200px]">Date</h1>
      ),
      selector: (row) => dayjs(row.date).format('DD-MM-YYYY'),
      sortable: true,
      // width: "200px",
    },

    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[240px]">
          Status
        </h1>
      ),
      selector: (row) => getStatus(row.status),
      sortable: true,
      // width: "240px",
    },
   
    {
      name: (
        <h1 className="text-lg text-gray-950 font-semibold w-[200px]">
          Product Value
        </h1>
      ),
      selector: (row) => parseFloat(row.total).toFixed(2),
      sortable: true,
      width: "200px",
    },
  ];

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  const handleDelete = async (id) => {
    confirmAlert({
      title: "Are you sure",
      message: "You want to delete this data ?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await axios.delete(`${apiUrl}/users/${id}`, config).then((res) => {
              //  console.log(res.status);
              if (res.status === 200) {
                setFilteredData((prevData) =>
                  prevData.filter((item) => item.id !== id)
                );
                toast.success(res.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "fixed top-14 right-1",
                });
              } else {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT,
                  className: "fixed top-14 right-1",
                });
              }
            });
          },
        },
        {
          label: "No",
          onClick: () => {
            toast.warn("cancelled", {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          },
        },
      ],
    });
  };

  //orders email code here...

  const [showDialog, setShowDialog] = useState(false);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  // add notes

  const [notes, setNotes] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [handleNote, setHandleNote] = useState(false);

  const showNoteModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    await axios
      .put(
        `${apiUrl}/users/notes/${id}`,
        {
          notes: notes,
        },
        config
      )
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          toast.success("Notes updated", {
            position: toast.POSITION.TOP_RIGHT,
            className: "fixed top-14 right-1 z-30",
          });
        } else {
          toast.error(res.data.message);
        }
      });
    setIsModalVisible(false);
    setHandleNote(!handleNote);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/users/${id}`, config)
      .then((res) => {
        console.log("res", res.data.data);
        if (res !== undefined) {
          setImg(res.data.data[0].image);
          setIdProof(res.data.data[0].id_proof);
          setMappedUsers(res.data.data[0]);
          setNotes(res.data.data[0].notes);

          // const mappedUsersData = res.data.data.map((user) => user);
          // setMappedUsers(mappedUsersData);
          // CustomersViewDetails(mappedUsersData);
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, [handleNote]);

  const [pending, setPending] = useState(true);

  useEffect(() => {
    axios
      .post(
        `${apiUrl}/getuserorders`,
        {
          user_id: id,
        },
        config
      )
      .then((res) => {
        if (res.data.data !== undefined) {
          if (res.status === 200) {
            console.log(res.data.data);
            setItem(res.data.data);
            setPending(false);
          }
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, []);

  useEffect(() => {
    const filterItem = (item) => {
      return Object.values(item).some((value) => {
        if (typeof value === "string") {
          const lowerCaseValue = value.toString().toLowerCase();
          return lowerCaseValue.includes(searchTerm.toLowerCase());
        } else if (value instanceof Date) {
          const dateValue = value.toISOString().toLowerCase();
          return dateValue.includes(searchTerm.toLowerCase());
        } else if (typeof value == "number") {
          const stringValue = value.toString().toLowerCase();
          return stringValue === searchTerm.toLowerCase();
        }
        return false;
      });
    };

    const filteredResult = item.filter(filterItem);

    setFilteredData(filteredResult);
  }, [item, searchTerm]);

  const customStyles = {
    header: {
      style: {
        textAlign: "center", // Center header text
      },
    },
    cells: {
      style: {
        textAlign: "center", // Center cell text
      },
    },
    headRow: {
      style: {
        border: "none",
        outerWidth: "600px",

        // border: '1px solid #105d50',
      },
    },
    headCells: {
      style: {
        paddingLeft: "30px", // override the cell padding for head cells
        paddingRight: "30px",
        backgroundColor: "#FBF7ED",
        color: "#1F2937",
        fontWeight: "400",

        // border: '1px solid #ddd',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#fff9f3",

        // borderBottomColor: "#FFFFFF",
        // outline: "1px solid #FFFFFF",
        // border: '1px solid #105d50',
      },
    },

    pagination: {
      style: {
        border: "none",
      },
    },
    cells: {
      style: {
        // width: '200px',
        paddingLeft: "40px",
        paddingRight: "40px",
        textAlign: "center", // Center-align text within cells
        fontWeight: "400",
        fontSize: "15px",
        color: "#1F2937",
        borderRight: "1px solid #ddd",

        // borderBottom: '1px solid #ddd',
      },
    },
  };

  const handleCsv = () => {
    if (item !== undefined) {
      const csvData = filteredData.map((item) => ({
        "User Name": item.username,
        "Mail ID": item.user_email,
        "Date": dayjs(item.created_at).format('DD-MM-YYYY'),
      }));
      return csvData;
    }
    return [];
  };

  const cancelButtonProps = {
    style: { color: "white", backgroundColor: "#EF4444" },
  };

  const okButtonProps = {
    style: { color: "white", backgroundColor: "#22C55E" },
  };

  console.log(idProof);

  const handleDownload = async () => {
    const fileUrl = `${imageUrl}/${idProof}`;

    if (idProof === null || idProof === "") {
      toast.info("ID Proof is not available", {
        position: toast.POSITION.TOP_RIGHT,
        className: "fixed top-14 right-1 z-30",
      });
    } else {
      try {
        const response = await fetch(fileUrl);
        const blob = await response.blob();

        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${mappedUsers?.first_name} ${mappedUsers?.last_name}`; // Set the desired file name

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
    }
  };

  // console.log(mappedUsers?.id_proof !== null);

  const handleViewIdProof = () => {
    if (mappedUsers?.id_proof === null || mappedUsers?.id_proof === "") {
      toast.info("ID proof is not available", {
        position: toast.POSITION.TOP_RIGHT,
        className: "fixed top-14 right-1 z-30",
      });
    } else {
      navigate(`/homepage/user/userdetailpage/customeridproof/${id}`);
    }
  };


  const handleRowClick = (row) => {
    // navigate("/homepage/viewDetails/new/" + row.id + "/" + row.assesment_id);
    navigate(`/homepage/dashboard/viewdetails/${row.id}/${id}`);
  };

  return (
    <div className="flex flex-col w-full h-fit  gap-8 mb-8">
      <div className="flex flex-row w-full justify-center items-center gap-8 ">
        <div className="w-3/12 h-[220px] bg-white rounded border drop-shadow p-4">
          <div className="w-full h-full flex flex-col gap-1">
            <div className="w-full flex flex-row items-center justify-between">
              <h1 className="w-full text-gray-700 text-lg font-bold ">
                ID Proof
              </h1>

              <div className="w-full flex items-center justify-end gap-3">
                <span
                  className="px-2 pb-[2px] bg-blue-500 text-white rounded cursor-pointer"
                  onClick={handleViewIdProof}
                >
                  view
                </span>

                <span>
                  {" "}
                  <FiDownload
                    className="w-6 h-5 cursor-pointer"
                    onClick={handleDownload}
                  />
                </span>
              </div>
            </div>
            <div className="w-full h-5/6 flex justify-center items-center">
              {mappedUsers?.id_proof === null ||
              mappedUsers?.id_proof === "" ? (
                <h1 className="text-gray-700 text-md font-semibold text-center">
                  ID Proof has to be upload
                </h1>
              ) : (
                <img
                  alt="idproof"
                  src={`${imageUrl}/${mappedUsers?.id_proof}`}
                  className="w-full h-full object-contain"
                />
              )}
              {/* {mappedUsers?.id_proof === "" ? (
                <h1 className="text-gray-700 text-md font-semibold text-center">
                  ID Proof has to be upload
                </h1>
              ) : (
                <img
                  alt="idproof"
                  src={`${imageUrl}/${mappedUsers?.id_proof}`}
                  className="w-full h-full object-contain"
                />
              )} */}
            </div>
          </div>
        </div>
        <div className="w-4/12 h-[220px] bg-white rounded border drop-shadow p-4">
          <div className="w-full flex flex-col gap-1.5">
            <h1 className="text-gray-700 text-lg font-bold ">
              Customer Details
            </h1>
            <span className="text-[16px] text-gray-600 font-medium capitalize flex flex-row gap-1">
              First Name: {mappedUsers?.first_name}
            </span>
            <span className="text-[16px] text-gray-600 font-medium capitalize flex flex-row gap-1">
              Last Name : {mappedUsers?.last_name}
            </span>
            <span className="text-[16px] text-gray-600 font-medium capitalize">
              DOB : {mappedUsers?.dob}
            </span>
            <span className="text-[16px] text-gray-600 font-medium ">
              Mail ID : {mappedUsers?.email}
            </span>
            <span className="text-[16px] text-gray-600 font-medium capitalize">
              Phone Number : {mappedUsers?.phoneno}
            </span>
          </div>
        </div>
        <div className="w-5/12 h-[220px] flex flex-row  bg-white rounded border drop-shadow p-4">
          <div className="w-full h-full flex flex-col gap-4">
            <div className="w-full h-1/6 flex flex-row justify-between items-start">
              <div>
                <span className="text-gray-700 text-lg font-bold ">Notes</span>
              </div>
              <div className="flex items-center gap-4">
                <span
                  className="py-1.5 px-4 text-white font-medium bg-primary rounded cursor-pointer 
                  shadow shadow-zinc-300 hover:bg-secondary duration-300"
                  onClick={showNoteModal}
                >
                  Add Notes
                </span>
                <Modal
                  title="Notes"
                  open={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  cancelButtonProps={cancelButtonProps}
                  okButtonProps={okButtonProps}
                >
                  <textarea
                    autoFocus
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                    placeholder="Enter your notes here..."
                    className="px-2 pt-1.5 pb-2 border border-gray-300 rounded h-[140px] w-full
                      text-md text-gray-700 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
                      "
                  />
                </Modal>
                <span
                  className="py-1.5 px-4 text-white font-medium bg-primary rounded cursor-pointer 
                  shadow shadow-zinc-300 hover:bg-secondary duration-300"
                  onClick={() => openDialog(id)}
                >
                  Send Message
                </span>
              </div>
            </div>

            <span className="w-full h-5/6 border-2 hover:border-gray-300 rounded overflow-y-scroll p-4">
              {mappedUsers?.notes}
            </span>
          </div>
        </div>
      </div>
      
      <div className="w-full flex  flex-col bg-white border drop-shadow rounded px-5 pt-8 pb-5">
        <div className="w-full pb-5">
          <span className="capitalize text-xl font-bold text-gray-800 ">
            Past Orders History
          </span>
        </div>
        <div className="w-full h-12 flex flex-wrap justify-between gap-8 items-center ">
          <input
            placeholder="search..."
            type="text"
            required
            className="p-2 text-lg border border-gray-400 
        rounded focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
          dark:bg-transparent dark:text-neutral-200 dark:focus:bg-transparent"
            onChange={(event) => setSearchTerm(event.target.value)}
            value={searchTerm}
          />
          <div>
            <CSVLink
              data={handleCsv()}
              filename="users.csv"
              className="py-1.5 px-4 sm:py-2 sm:px-7 bg-primary text-lg text-white shadow shadow-primary hover:shadow-secondary hover:bg-secondary ease-in-out duration-300 text-normal font-semibold
      rounded-md"
            >
              Export
            </CSVLink>
          </div>
        </div>
        <div className="mt-8">
          <DataTable
            fixedHeader={true}
            columns={columns}
            data={filteredData}
            pagination
            customStyles={customStyles}
            highlightOnHover
            pointerOnHover
            fixedHeaderScrollHeight="310px"
            onRowClicked={handleRowClick}
          />
        </div>
      </div>
      <CustomersEmail isOpen={showDialog} onClose={closeDialog} id={id} />
      
    </div>
  );
};

export default CustomersDetailPage;
