import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { RxHamburgerMenu } from "react-icons/rx";
import { VscBellDot } from "react-icons/vsc";
import { MdDashboard, MdOutlineInventory } from "react-icons/md";
import { useState, useRef, useEffect } from "react";
import { FaTimes, FaUserCircle } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import { IoMdSettings } from "react-icons/io";
import { TbLogout } from "react-icons/tb";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import weightloss_log from "../images/Anti-Malarial-web-logo.svg";
import { HiOutlineMail } from "react-icons/hi";
import { RiUserSettingsFill } from "react-icons/ri";
import { VscReferences } from "react-icons/vsc";
import { BsDatabaseFillGear } from "react-icons/bs";
import { MdShoppingCart } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { MdOutlineAssessment } from "react-icons/md";
import { RiMoneyPoundCircleFill } from "react-icons/ri";


const apiUrl = process.env.REACT_APP_API_URL;

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const NavBar = ({ pic, getRoleName, getName }) => {
  const [nav, setNav] = useState(false);

  const [showlog, setShowlog] = useState(false);

  const getData = localStorage.getItem("weightloss_role");

  const getStaffId = localStorage.getItem("weightloss_user_id");

  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear();
    navigate("/");
  };

  const menuRef = useRef(null);
  //  const sideRef = useRef(null);

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef.current.contains(e.target)) {
        setShowlog(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  console.log("pic",pic)

  return (
    <>
      <div
        className="w-full lg:w-4/5  h-16 flex flex-row justify-between px-4 fixed left-1/5 right-0 top-0
      bg-fourth  z-20 border-b border-stone-200"
      >
        <div className="flex flex-row justify-center items-center">
          <div className="w-12 h-6 ">
            <RxHamburgerMenu
              className="w-6 h-6 sm:w-8 sm:h-6 lg:hidden text-nav-ash cursor-pointer"
              onClick={() => setNav(!nav)}
            />
          </div>
          <AiOutlineSearch className="w-6 h-6 sm:w-8 sm:h-6  text-nav-ash" />
        </div>
        <div
          className={`${
            nav &&
            "fixed inset-0 bg-opacity-50 backdrop-blue-[1px] bg-primary duration-700 ease-in-out "
          } `}
        >
          <div
            className={`flex flex-col w-10/12  md:w-8/12 h-screen  lg:hidden absolute left-0 top-0 bottom-0   
                 ${
                   nav ? "translate-x-0" : "-translate-x-full overflow-hidden "
                 } ease-in-out duration-700  overflow-y-scroll`}
          >
            <div className="w-full h-46 px-6 py-8 flex flex-col  bg-primary gap-6 border-b-2 border-gray-600">
              <div className="flex flex-row justify-between gap-6">
                {/* <img src={green} alt="RBF" className="w-24 h-20 rounded-sm" /> */}
                <div className=" w-full flex flex-row justify-between items-center">
                  <h1 className="text-2xl font-bold text-secondary m-auto capitalize ">
                    {getRoleName}
                  </h1>
                  <FaTimes
                    className="w-6 h-6 text-secondary  cursor-pointer"
                    onClick={() => setNav(!nav)}
                  />
                </div>
              </div>
              <div className="flex justify-center w-full h-fit px-6 lg:px-10 py-6 rounded-lg">
                <img
                  src={weightloss_log}
                  alt="RBF"
                  className="w-full h-fit rounded-sm"
                />
              </div>
              {/* <div className="h-1 w-full bg-gray-600"></div> */}
              {/* <div className="w-full bg-white h-1"></div> */}
              {/* <div className="flex flex-row w-full p-3 justify-between bg-regal-blue rounded-lg">
                <div>
                  <h6 className="font-bold text-xl text-gray-100">Vicky</h6>
                  <p className=" text-gray-400">Production</p>
                </div>
                <div className="flex justify-center items-center">
                  <TiArrowUnsorted className="w-6 h-6 text-gray-300" />
                </div>
              </div> */}
            </div>
            

            {getData === "5" && (
              <>
                <div className="flex flex-col justify-start px-6 gap-2  py-6  h-full bg-primary">
                  <NavLink
                    to="/homepage/user"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row   items-center gap-4 p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 hover:duration-200 rounded-lg hover:ease-in-out "
                      onClick={() => setNav(!nav)}
                    >
                      <FaUsers className="text-xl" /> Customers
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/products"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <MdShoppingCart className="text-xl" /> Medicines
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/ordermenu/neworder"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <MdOutlineInventory className="text-xl" /> Orders
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/membermenu/doctors"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <RiAdminFill className="text-xl" /> Staffs
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/roles"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700  rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <RiUserSettingsFill className="text-xl" /> Roles
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/referral"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700  rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <VscReferences className="text-xl" /> Referral
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/configs"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <BsDatabaseFillGear className="text-xl" /> Configs
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/assessmentquestions"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <MdOutlineAssessment className="text-xl" /> Assessment
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/emailtemplate"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row  items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <HiOutlineMail className="text-xl" /> Email Template
                    </li>
                  </NavLink>
                </div>
              </>
            )}
            {getData === "1" && (
              <>
                <div className="flex flex-col justify-start px-6 gap-2 p-6 h-full bg-primary">
                  <NavLink
                    to="/homepage/dashboard"
                    isActive={(match, location) => {
                      // Check if the current location matches the menu's path or any of its subpaths
                      return (
                        match ||
                        location.pathname.startsWith(
                          "/homepage/dashboard" + "/"
                        ) // Assuming child routes have paths like /homepage/institutes/child
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "4px",
                            color: "#F97316",

                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row   items-center gap-4 p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 hover:duration-200 rounded hover:ease-in-out "
                      onClick={() => setNav(!nav)}
                    >
                      <MdDashboard className="text-xl" /> DashBoard
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/user"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row   items-center gap-4 p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 hover:duration-200 rounded-lg hover:ease-in-out "
                      onClick={() => setNav(!nav)}
                    >
                      <FaUsers className="text-xl" /> Customers
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/products"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <MdShoppingCart className="text-xl" /> Medicines
                    </li>
                  </NavLink>
                  {/* <NavLink
                    to="/homepage/ordermenu/neworder"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <MdOutlineInventory className="text-xl" /> Orders
                    </li>
                  </NavLink> */}
                  <NavLink
                    to="/homepage/staffs"
                    isActive={(match, location) => {
                      // Check if the current location matches the menu's path or any of its subpaths
                      return (
                        match ||
                        location.pathname.startsWith("/homepage/staffs" + "/") // Assuming child routes have paths like /homepage/institutes/child
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "4px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <RiAdminFill className="text-xl" /> Staffs
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/referral"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700  rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <VscReferences className="text-xl" /> Referral
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/configs"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <BsDatabaseFillGear className="text-xl" /> Configs
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/assessmentquestions"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <MdOutlineAssessment className="text-xl" /> Assessment
                    </li>
                  </NavLink>
                  <NavLink
                    to="/homepage/emailtemplate"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row  items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <HiOutlineMail className="text-xl" /> Email Template
                    </li>
                  </NavLink>
                </div>
              </>
            )}

            {getData === "2" && (
              <>
                <div className="flex flex-col justify-start px-6 gap-1.5  py-6  h-full bg-primary">
                  <NavLink
                    to="/homepage/dashboard"
                    isActive={(match, location) => {
                      // Check if the current location matches the menu's path or any of its subpaths
                      return (
                        match ||
                        location.pathname.startsWith(
                          "/homepage/dashboard" + "/"
                        ) // Assuming child routes have paths like /homepage/institutes/child
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <MdOutlineInventory className="text-xl" /> Orders
                    </li>
                  </NavLink>
                </div>
              </>
            )}
            {getData === "3" && (
              <>
                <div className="flex flex-col justify-start px-6 gap-1.5  py-6  h-full bg-secondary ">
                  <NavLink
                    to="/homepage/dashboard"
                    isActive={(match, location) => {
                      // Check if the current location matches the menu's path or any of its subpaths
                      return (
                        match ||
                        location.pathname.startsWith(
                          "/homepage/dashboard" + "/"
                        ) // Assuming child routes have paths like /homepage/institutes/child
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <MdOutlineInventory className="text-xl" /> Order
                    </li>
                  </NavLink>
                </div>
              </>
            )}
            {getData === "4" && (
              <>
                <div className="flex flex-col justify-start px-6 gap-1.5  py-6  h-full bg-primary">
                  <NavLink
                    to="/homepage/approvedorder"
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "8px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                      onClick={() => setNav(!nav)}
                    >
                      <MdOutlineInventory className="text-xl" /> Order
                    </li>
                  </NavLink>
                </div>
              </>
            )}
          </div>
        </div>

        <div
          className="flex flex-row justify-center items-center gap-2"
          ref={menuRef}
        >
          <VscBellDot className="w-12 h-6 text-nav-ash " />
          <div
            className="cursor-pointer w-9 h-9 border rounded-full border-gray-400"
            onClick={() => setShowlog(!showlog)}
          >
            {pic[0] === "undefined" || pic[0] === "" ? (
              <>
                <FaUserCircle className="w-full h-full text-gray-400" />
              </>
            ) : (
              <>
                {pic.map((i, id) => (
                  <React.Fragment key={id}>
                    <img
                      src={`${imageUrl}/${i}`}
                      // alt="profile1"
                      className="w-full h-full rounded-full object-cover"
                    />
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
          {showlog && (
            <>
              <div className="fixed top-20 right-4 h-fit w-[145px] bg-white flex flex-col rounded-lg shadow border border-gray-50">
                <div className="flex flex-col p-3 gap-2">
                  <span className="font-semibold  text-gray-800">Account</span>
                  <span className="text-nav-ash font-md capitalize">
                    {getName}
                  </span>
                </div>
                <div className="w-full h-px bg-gray-300"></div>
                <div className="w-full flex flex-col p-2 gap-2">
                  <div className="flex flex-col  justify-between">
                    <Link to={`/homepage/setting/${getStaffId}`}>
                      {" "}
                      <span
                        className="flex flex-row items-center gap-2 cursor-pointer text-nav-ash px-2 py-1 font-md hover:rounded hover:duration-300  hover:bg-regal-blue hover:text-white"
                        onClick={() => setShowlog(!showlog)}
                      >
                        <IoMdSettings className="text-[18px]" /> Settings
                      </span>
                    </Link>
                  </div>
               
                  {getData === "1" && (
                    <>
                      {" "}
                      <div className="flex flex-col  justify-between ">
                        <Link to={`/homepage/feedetails`}>
                          <span
                            className="flex flex-row items-center gap-2 cursor-pointer px-2 py-1
                      hover:bg-primary hover:text-white hover:rounded hover:duration-300 text-nav-ash font-md"
                            onClick={() => setShowlog(!showlog)}
                          >
                            <RiMoneyPoundCircleFill className="text-[18px]" />
                            Fee-Details
                          </span>
                        </Link>
                      </div>
                    </>
                  )}

                  {getData === "2" && (
                    <>
                      {" "}
                      <div className="flex flex-col  justify-between ">
                        <Link to={`/homepage/showfeedetails`}>
                          <span
                            className="flex flex-row items-center gap-2 cursor-pointer px-2 py-1
                      hover:bg-primary hover:text-white hover:rounded hover:duration-300 text-nav-ash font-md"
                            onClick={() => setShowlog(!showlog)}
                          >
                            <RiMoneyPoundCircleFill className="text-[18px]" />
                            Fee Earned
                          </span>
                        </Link>
                      </div>
                    </>
                  )}
               
                  <div className="flex flex-col  justify-between">
                    <span
                      className="flex flex-row items-center gap-2 cursor-pointer px-2 py-1
                      hover:bg-primary hover:text-white hover:rounded hover:duration-300 text-nav-ash font-md"
                      onClick={logOut}
                    >
                      <TbLogout className="text-[18px]" />
                      Log out
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NavBar;
