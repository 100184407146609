import React, { useEffect, useState } from "react";
import { Document, Font, Page, Text, StyleSheet } from "@react-pdf/renderer";
import { PDFViewer } from "@react-pdf/renderer";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import dayjs from "dayjs";

const apiUrl = process.env.REACT_APP_API_URL;

export const PrescriptionComponent = ({ productId }) => {
  const [prescription, setPrescription] = useState(null);

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  useEffect(() => {
    const fetchPrescription = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/products/basic_details/${productId}`,
          config
        );
        setPrescription(response.data.data[0]?.prescription);
      } catch (error) {
        console.error("Error fetching prescription:", error);
      }
    };

    fetchPrescription();
  }, [productId]); // Ensure useEffect runs whenever productId changes

  return <>{prescription ? prescription : ""}</>;
};

const DocumentPdf = () => {
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 8,
      color: "gray",
    },
    author: {
      fontSize: 12,
      marginBottom: 20,
    },
    author2: {
      fontSize: 12,
    },
    author3: {
      fontSize: 12,
      marginBottom: 172,
    },
    author1: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 40,
      color: "gray",
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: "Oswald",
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
      fontFamily: "Times-Roman",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    h1: {
      fontSize: 14,
      textAlign: "left",
      // textDecoration: "underline",
    },
    h2: {
      fontSize: 14,
      textAlign: "right",
      // textDecoration: "underline",
    },

    flex: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignContent: "center",
      marginBottom: 30,
      columnGap: "5px",
    },
    flex2: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignContent: "flex-start",
      // marginBottom: 15,
      columnGap: "2px",
    },
    flex1: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignContent: "flex-start",
      rowGap: "15px",
      marginBottom: 20,
    },
    content: {
      padding: 20,
      "@media max-width: 400": {
        flexDirection: "column",
      },
      "@media min-width: 400": {
        flexDirection: "row",
      },
    },
    box: {
      width: "72px",
      height: "20px",
      border: "1px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
    },
    box1: {
      width: "100%",
      height: "20px",
      border: "1px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "center",
    },
  });

  Font.register({
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  });

  const Subtitle = ({ children, ...props }) => (
    <Text style={styles.subtitle} {...props}>
      {children}
    </Text>
  );

  const { id, user_id } = useParams();

  console.log(user_id, "userrid");

  const [getAnswers, setSetAnswers] = useState([]);
  const [notes, setNotes] = useState();
  const [userId, setUserId] = useState();
  const [prescription, setPrescription] = useState();
  const [dob, setDob] = useState();

  const [item, setItem] = useState([]);
  // console.log(item[0].username)

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/orders/${id}`, config)
      .then((res) => {
        setItem(res.data.data);
        setNotes(res.data.data[0].notes);
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  }, []);


  const [orderItem, setOrderItem] = useState([]);

  useEffect(() => {
    axios
      .get(`${apiUrl}/users/${user_id}`, config)
      .then((res) => {
        setDob(res.data.data[0].dob);
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
    axios
      .get(`${apiUrl}/getorderitems/${id}`)
      .then((res) => {
        console.log(res.data.data);
        if (res !== undefined) {
          setOrderItem(res.data.data);
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });

  }, []);


  const today = new Date();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="w-full pb-5 flex justify-end">
        <Link to={`/homepage/dashboard/viewdetails/${id}/${user_id}`}>
          <button className="px-8 py-2 bg-gray-800 text-white hover:bg-gray-700 duration-500 rounded">
            Go Back
          </button>
        </Link>
      </div>
      <PDFViewer style={{ width: "100%", height: "100vh" }}>
        <Document>
          {orderItem.map((value) => (
            <Page style={styles.body} key={value.id}>
              <Text style={styles.title}>PRESCRIPTION COUNTER</Text>
              <Text style={styles.title}>
                160 GILLETT ROAD, THORNTON HEATH, CR7 8SN{" "}
              </Text>
              <Text style={styles.author1}>02036593149</Text>
              <view style={styles.flex}>
                <Text style={styles.h1}>PRIVATE PRESCRIPTION:</Text>
                <view style={styles.flex2}>
                  <Text style={styles.h2}>
                    Date :{" "}
                    {`${today.getDate()}-${
                      today.getMonth() + 1
                    }-${today.getFullYear()}`}
                  </Text>
                </view>
              </view>
              {item.map((d) => (
                <view style={styles.flex1} key={d.id}>
                  <view style={styles.flex2}>
                    <Text style={styles.author2}>
                      NAME :{" "}
                      <Text style={styles.h2}>{value.traveller_name}</Text>{" "}
                    </Text>
                  </view>

                  <Text style={styles.author2}>
                    DOB : {dayjs(value.dob).format("DD-MM-YYYY")}
                  </Text>
                  <Text style={styles.author2}>
                    ADDRESS : {d.delivery_address}
                  </Text>
                  <Text style={styles.author2}>POSTCODE : {d.post_code}</Text>
                  <Text style={styles.author2}>Mail ID : {d.user_email}</Text>
                  <Text style={styles.author2}>
                    Medicine : {value.medicine_name}
                  </Text>
                </view>
              ))}

              <Text style={styles.author3}>
                NOTES : {notes}
              </Text>
              <Text style={styles.author3}>
                RX : {value.product_id && (
                  <PrescriptionComponent productId={value.product_id} />
                )}
              </Text>
              {/* <Text style={styles.author}>SIGNATURE</Text> */}
              <Text style={styles.author}>RITESH APPADOO</Text>
              <Text style={styles.author}>MPHARM IP, 2070941</Text>

              <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) =>
                  `${pageNumber} / ${totalPages}`
                }
                fixed
              />
            </Page>
          ))}
        </Document>
      </PDFViewer>
    </>
  );
};

export default DocumentPdf;
