import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Textarea,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import {
  AlertDialog,

  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  
} from "@chakra-ui/react";
import { useRef } from "react";

const apiUrl = process.env.REACT_APP_API_URL;

export default function OrdersEmail({ isOpen, onClose, id }) {
  const [editMode, setEditMode] = useState(false);
  const [getFromEmail, setGetFromEmail] = useState("");
  const [getToEmail, setGetToEmail] = useState("");

  // console.log(isOpen, onClose, id)

  const initialstate = {
    toEmail: "",
    subject: "",
    message: "",
  };

  const [emailForm, setEmailForm] = useState(initialstate);

  const [name, setName] = useState("");

  useEffect(() => {
    if (id) {
      axios
        .get(`${apiUrl}/orders/${id}`, config)
        .then((res) => {
          console.log(res.data.data);
          setGetToEmail(res.data.data[0].user_email);
          setName(res.data.data[0].username);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });

      // axios
      //   .post(
      //     `${apiUrl}/doctors/getemail`,
      //     {
      //       code: localStorage.getItem("weightloss_code"),
      //     },
      //     {
      //       headers: {
      //         authorization: `Bearer ${localStorage.getItem("token_id")}`,
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     // console.log(res.data.data);
      //     setGetToEmail(res.data.data[0].email);
      //   })
      //   .catch((err) => {
      //     toast.error(err, {
      //       position: toast.POSITION.TOP_CENTER,
      //     });
      //   });
    }
  }, [id]);

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const sendEmail = async (e) => {
    e.preventDefault();

    console.log("trigger");
    let formData = {
      toEmail: getToEmail,
      subject: emailForm.subject,
      username: name,
      message: emailForm.message,
    };

    await axios.post(`${apiUrl}/sendEmail`, formData, config).then((res) => {
      console.log(res.data);
      if (res.status === 200) {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "fixed top-14 right-1 ",
        });
        onClose();
      } else {
        toast.error(res.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "fixed top-14 right-1",
        });
      }
    });
  };

  // console.log(emailForm.fromEmail)

  const cancelRef = useRef();

  return (
    <div className="flex flex-col absolute left-1/5 right-0 top-44 w-full lg:w-4/5 pt-10 px-5 sm:px-8 z-0 ">
      {/* <Button onClick={isOpen}>Discard</Button> */}
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent w={[300, 400, 500]} p={10}>
          <Flex align={"center"} justify={"center"}>
            <Stack
              spacing={6}
              mx={"auto"}
              maxW={"lg"}
              //   py={6}
              //   px={6}
              w={[300, 400, 500]}
            >
              <Box
                // rounded={"lg"}
                bg={useColorModeValue("white")}
                // boxShadow={"lg"}
                border="solid 1px red.500"
                columnGap="10"
                h="auto"
              >
                <form onSubmit={sendEmail}>
                  <Stack spacing={5}>
                    <AlertDialogCloseButton />
                    {/* <FormControl id="fromemail">
                      <FormLabel>Email address</FormLabel>
                      <Input
                        type="email"
                        // readOnly
                        focusBorderColor="#36D399"
                        placeholder="senders's Email Address"
                        value={emailForm.fromEmail}
                        onChange={(e) =>
                          setEmailForm({
                            ...emailForm,
                            fromEmail: e.target.value,
                          })
                        }
                      />
                    </FormControl> */}
                    <FormControl id="toemail">
                      <FormLabel>Order Email address</FormLabel>
                      <Input
                        type="email"
                       
                        focusBorderColor="#1F2937"
                        placeholder="Receiver's Email Address"
                        readOnly
                        value={getToEmail}
                        // value={emailForm.toEmail}
                        // onChange={(e) =>
                        //   setEmailForm({
                        //     ...emailForm,
                        //     toEmail: e.target.value,
                        //   })
                        // }
                      />
                    </FormControl>
                    <FormControl id="text">
                      <FormLabel>Subject</FormLabel>
                      <Input
                       focusBorderColor="#1F2937"
                        onChange={(e) =>
                          setEmailForm({
                            ...emailForm,
                            subject: e.target.value,
                          })
                        }
                        type="text"
                        placeholder="Enter the subject here..."
                      />
                    </FormControl>
                    <FormControl id="text">
                      <FormLabel>Message</FormLabel>
                      <Textarea
                        focusBorderColor="#1F2937"
                        h="130px"
                        onChange={(e) =>
                          setEmailForm({
                            ...emailForm,
                            message: e.target.value,
                          })
                        }
                        placeholder="Enter your message here..."
                      />
                    </FormControl>
                    <Stack spacing={10}>
                      <Button
                     bg={"#1F2937"}
                     color={"white"}
                     _hover={{
                       bg: "#F97316",
                     }}
                     type="submit"
                      >
                        Send Email
                      </Button>
                    </Stack>
                  </Stack>
                </form>
              </Box>
            </Stack>
          </Flex>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
