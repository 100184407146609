import React from "react";
import { useState} from "react";
import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import weightloss_log from "../images/Anti-Malarial-web-logo.svg";

const apiUrl = process.env.REACT_APP_API_URL;

const Login = () => {
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const Navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${apiUrl}/auth/login`, {
        email: form.email,
        password: form.password,
      });

      // console.log(response.data);

      if (response.data.error === false && response.data.data[0].status === 1) {
        localStorage.setItem("token_id", response.data.accessToken);
        localStorage.setItem("weightloss_role", response.data.data[0].role);
        localStorage.setItem("weightloss_code", response.data.data[0].code);
        localStorage.setItem("weightloss_user_id", response.data.data[0].id);
        localStorage.setItem("refreshtoken_id", response.data.refreshToken);
        localStorage.setItem("auth", response.data.auth);

        // if (
        //   response.data.data[0].role === "3" ||
        //   response.data.data[0].role === "4"
        // ) {
        //   Navigate("/homepage/approvedorder");
        // } else if (response.data.data[0].role === "2") {
        //   Navigate("/homepage/ordermenu/neworder");
        // } else {
          Navigate("/homepage/dashboard");
        // }
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "fixed top-14 right-1",
        });
        setForm({ email: "", password: "" });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
          className: "fixed top-14 right-1",
        });
      }
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
        className: "fixed top-14 right-1",
      });
    }
  };

  return (
    <div className="h-screen w-full px-6 flex justify-center items-center flex-row bg-primary ">
      <div className="w-96 bg-white p-6 sm:p-8 shadow-xl grid gap-4 rounded-lg">
        <div className="w-full h-28 grid">
          <img
            src={weightloss_log}
            alt="RBF"
            className="w-full h-[68px] rounded-sm my-auto"
          />
          {/* <h1 className="text-xl sm:text-2xl font-semibold  text-transparent bg-clip-text bg-gradient-to-br from-primary to-secondary m-auto ">
            Weight loss Program
          </h1> */}
        </div>
        <div className="">
          <form className="flex flex-col mb-5" onSubmit={handleSubmit}>
            <label className="py-3 text-gray-800 text-base sm:text-[17px] font-medium">
              Email Address
            </label>
            <input
              placeholder="Enter your email..."
              type="email"
              required
              className="py-2 px-2 bg-gray-100 text-gray-500 rounded-md focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
         "
              value={form.email}
              onChange={(e) => setForm({ ...form, email: e.target.value })}
            />
            <label className="py-3 text-gray-800 text-base sm:text-[17px] font-medium">
              Password
            </label>
            <input
              placeholder="Enter your Password..."
              type="password"
              className="py-2 px-2 bg-gray-100 mb-4 text-gray-500 rounded-md focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
          "
              value={form.password}
              onChange={(e) => setForm({ ...form, password: e.target.value })}
              required
            />

            <button
              className="py-1 bg-primary text-white shadow-sm shadow-primary hover:bg-secondary ease-in-out duration-300 text-base sm:text-lg font-sans font-semibold cursor-pointer
           rounded-md mt-4  "
              type="submit"
            >
              Login
            </button>
          </form>
          <div className="flex flex-row justify-center mt-4">
            {/* <Link to="/forgetpassword"> */}
            <button className="py-3  font-sans text-gray-400 font-base hover:text-transparent bg-clip-text bg-gradient-to-br from-primary to-secondary cursor-pointer">
              Forgot Password ?
            </button>
            {/* </Link> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Login;
