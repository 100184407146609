import React from "react";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import axios from "axios";
import { toast} from "react-toastify";
import { useParams, useNavigate } from "react-router";

const apiUrl = process.env.REACT_APP_API_URL;

const FeeDetailsForm = () => {
  const initialstate = {
    // doctor_percentage: "",
    prescriber_percentage: "",
  };

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  const [form, setForm] = useState(initialstate);

  console.log(form)

  const [editMode, setEditMode] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!editMode) {
      await axios
        .post(
          `${apiUrl}/fee_details`,
          {
            // doctor_percentage: form.doctor_percentage,
            prescriber_percentage: form.prescriber_percentage,
            created_by: 1,
          },
          config
        )
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    } else {
      await axios
        .put(
          `${apiUrl}/fee_details/${id}`,
          {
            // doctor_percentage: form.doctor_percentage,
            prescriber_percentage: form.prescriber_percentage,
            created_by: 1,
          },
          config
        )
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            toast.success(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
            // navigate("/roles");
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
              className: "fixed top-14 right-1",
            });
          }
        });
    }
  };

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setEditMode(true);
      getFormData(id);
    } else {
      setEditMode(false);
      setForm({ ...initialstate });
    }
  }, [id]);

  const getFormData = async (id) => {
    const formData = await axios.get(`${apiUrl}/fee_details/${id}`, config);
    console.log(formData.data.data);
    if (formData.status === 200) {
      setForm({ ...formData.data.data[0] });
    } else {
      console.log("Something went wrong !!");
    }
  };

  return (
    <div
      
    >
      <div className="flex flex-rows justify-center items-center w-full  h-12 my-8 ">
        <h2 className="text-xl md:text-2xl  font-semibold text-globalBlack w-full">
          Form
        </h2>
        <Link to="/homepage/feedetails">
          <FaTimes className="w-12 h-6 cursor-pointer text-gray-500" />
        </Link>
      </div>
      <div className="w-full h-px bg-gray-400"></div>
      <form onSubmit={handleSubmit}>
        <div className="w-full h-full grid grid-cols-1  gap-10">
          {/* <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
            <div>
              <label className="text-lg font-medium text-gray-500">
                Doctor_percentage
              </label>
            </div>
            <div className="grid grid-cols-1">
              <input
                type="text"
                required
                name="doctor_percentage"
                value={form.doctor_percentage}
                onChange={(e) =>
                  setForm({ ...form, doctor_percentage: e.target.value })
                }
                placeholder="Enter doctor_percentage here.."
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded-md
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-secondary focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div> */}
          <div className="h-28 grid grid-cols-1  items-center p-3 gap-2 sm:mx-4 ">
            <div>
              <label className="text-lg font-medium text-globalBlack">
                Prescriber_percentage
              </label>
            </div>
            <div className="grid grid-cols-1">
              <input
                type="text"
                required
                name="precriber_percentage"
                value={form.prescriber_percentage}
                onChange={(e) =>
                  setForm({ ...form, prescriber_percentage: e.target.value })
                }
                placeholder="Enter precriber_percentage here..."
                className="px-2 pt-1.5 pb-2 border border-gray-300 rounded
        text-md text-gray-400 focus:border-primary focus:bg-white focus:text-globalBlack focus:shadow-[0_0_0_1px] focus:shadow-primary focus:outline-none 
       "
              />
            </div>
          </div>
          <div className="flex flex-row justify-center items-center my-16 gap-6 ">
            <button
              className="py-2 px-7 bg-primary text-white shadow shadow-primary hover:bg-secondary ease-in-out duration-300 text-normal font-semibold
      rounded-md "
              type="submit"
            >
              {editMode ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </form>
     
    </div>
  );
};

export default FeeDetailsForm;
