import {  MdDashboard } from "react-icons/md";
import { RiAdminFill } from "react-icons/ri";
import { NavLink, useNavigate } from "react-router-dom";
import { MdOutlineInventory } from "react-icons/md";
import { VscReferences } from "react-icons/vsc";
import { BsDatabaseFillGear } from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import { MdOutlineAssessment } from "react-icons/md";
import weightloss_log from "../images/Anti-Malarial-web-logo.svg";
import { HiOutlineMail } from "react-icons/hi";
import { AiOutlineMedicineBox } from "react-icons/ai";

const SideBar = ({ getRoleName }) => {
  const getData = localStorage.getItem("weightloss_role");

  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate(`/homepage/dashboard`);
  };

  return (
    <>
      <div>
        {getData === "1" && (
          <>
            <div className="lg:flex flex-col w-1/5  h-screen  hidden md:fixed left-0 top-0 bg-primary overflow-y-scroll" style={{ scrollbarWidth: "none" }}>
              <div className="w-full h-[25%] p-[10%] flex flex-col gap-4 border-b-2 border-gray-600">
                <h1 className="text-2xl font-bold text-third m-auto capitalize">
                  {getRoleName}
                </h1>
                <div
                  className="flex justify-center w-full h-fit cursor-pointer"
                  onClick={navigateToDashboard}
                >
                  <img
                    src={weightloss_log}
                    alt="RBF"
                    className="w-full h-fit rounded-sm"
                  />
                </div>
                {/* <div className="flex flex-row w-full mt-4 p-3 justify-between bg-regal-blue rounded-lg">
                  <div>
                    <h6 className="font-bold text-xl text-gray-100">Vicky</h6>
                    <p className=" text-gray-400">Production</p>
                  </div>
                  <div className="flex justify-center items-center">
                    <TiArrowUnsorted className="w-4 h-4 text-gray-400 " />
                  </div>
                </div> */}
                
              </div>

              <div className="flex flex-col justify-start p-[10%] gap-2  h-fit ">
                <NavLink
                  to="/homepage/dashboard"
                  isActive={(match, location) => {
                    // Check if the current location matches the menu's path or any of its subpaths
                    return (
                      match ||
                      location.pathname.startsWith("/homepage/dashboard" + "/") // Assuming child routes have paths like /homepage/institutes/child
                    );
                  }}
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          backgroundColor: "#364152",
                          borderRadius: "4px",
                          color: "#F97316",
                        }
                      : {
                          color: "#94a3b4",
                        };
                  }}
                >
                  <li
                    className="flex flex-row   items-center gap-4 p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 hover:duration-200 rounded hover:ease-in-out "
                  >
                    <MdDashboard className="text-xl" /> DashBoard
                  </li>
                </NavLink>
                <NavLink
                  to="/homepage/user"
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          backgroundColor: "#364152",
                          borderRadius: "4px",
                          color: "#F97316",
                        }
                      : {
                          color: "#94a3b4",
                        };
                  }}
                >
                  <li
                    className="flex flex-row   items-center gap-4 p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 hover:duration-200 rounded hover:ease-in-out "
                  >
                    <FaUsers className="text-xl" /> Customers
                  </li>
                </NavLink>
                {/* <NavLink
                  to="/homepage/roles"
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          backgroundColor: "#364152",
                          borderRadius: "4px",
                          color: "#E9B208",
                        }
                      : {
                          color: "#94a3b4",
                        };
                  }}
                >
                  <li
                    className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700  rounded-lg  hover:duration-200 hover:ease-in-out"
                  >
                    <RiUserSettingsFill className="text-xl" /> Roles
                  </li>
                </NavLink> */}
                <NavLink
                  to="/homepage/products"
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          backgroundColor: "#364152",
                          borderRadius: "4px",
                          color: "#F97316",
                        }
                      : {
                          color: "#94a3b4",
                        };
                  }}
                >
                  <li
                    className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded  hover:duration-200 hover:ease-in-out"
                  >
                    <AiOutlineMedicineBox className="text-xl" /> Medicines
                  </li>
                </NavLink>
                {/* <NavLink
                  to="/homepage/ordermenu/neworder"
                  // isActive={(match, location) => {
                  //   // Check if the current location matches the menu's path or any of its subpaths
                  //   return (
                  //     match || location.pathname.startsWith("/homepage/ordermenu" + "/") // Assuming child routes have paths like /homepage/institutes/child
                  //   );
                  // }}

                  style={({ isActive }) => {
                    return isActive
                      ? {
                          backgroundColor: "#364152",
                          borderRadius: "4px",
                          color: "#E9B208",
                        }
                      : {
                          color: "#94a3b4",
                        };
                  }}
                >
                  <li
                    className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded  hover:duration-200 hover:ease-in-out"
                  >
                    <MdOutlineInventory className="text-xl" /> Orders
                  </li>
                </NavLink> */}
                <NavLink
                  to="/homepage/staffs"
                  isActive={(match, location) => {
                    // Check if the current location matches the menu's path or any of its subpaths
                    return (
                      match ||
                      location.pathname.startsWith("/homepage/staffs" + "/") // Assuming child routes have paths like /homepage/institutes/child
                    );
                  }}
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          backgroundColor: "#364152",
                          borderRadius: "4px",
                          color: "#F97316",
                        }
                      : {
                          color: "#94a3b4",
                        };
                  }}
                >
                  <li
                    className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded  hover:duration-200 hover:ease-in-out"
                  >
                    <RiAdminFill className="text-xl" /> Staffs
                  </li>
                </NavLink>
                <NavLink
                  to="/homepage/referral"
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          backgroundColor: "#364152",
                          borderRadius: "4px",
                          color: "#F97316",
                        }
                      : {
                          color: "#94a3b4",
                        };
                  }}
                >
                  <li
                    className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700  rounded  hover:duration-200 hover:ease-in-out"
                  >
                    <VscReferences className="text-xl" /> Referral
                  </li>
                </NavLink>
                <NavLink
                  to="/homepage/configs"
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          backgroundColor: "#364152",
                          borderRadius: "4px",
                          color: "#F97316",
                        }
                      : {
                          color: "#94a3b4",
                        };
                  }}
                >
                  <li
                    className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded  hover:duration-200 hover:ease-in-out"
                  >
                    <BsDatabaseFillGear className="text-xl" /> Configs
                  </li>
                </NavLink>
                <NavLink
                  to="/homepage/assessmentquestions"
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          backgroundColor: "#364152",
                          borderRadius: "4px",
                          color: "#F97316",
                        }
                      : {
                          color: "#94a3b4",
                        };
                  }}
                >
                  <li
                    className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded  hover:duration-200 hover:ease-in-out"
                  >
                    <MdOutlineAssessment className="text-xl" /> Assessment
                  </li>
                </NavLink>
                <NavLink
                  to="/homepage/emailtemplate"
                  
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          backgroundColor: "#364152",
                          borderRadius: "4px",
                          color: "#F97316",
                        }
                      : {
                          color: "#94a3b4",
                        };
                  }}
                >
                  <li
                    className="flex flex-row lg:items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded  hover:duration-200 hover:ease-in-out"
                  >
                    <HiOutlineMail className="text-xl" /> Email Template
                  </li>
                </NavLink>
                <NavLink
                  to="/homepage/contactus"
                  
                  style={({ isActive }) => {
                    return isActive
                      ? {
                          backgroundColor: "#364152",
                          borderRadius: "4px",
                          color: "#F97316",
                        }
                      : {
                          color: "#94a3b4",
                        };
                  }}
                >
                  <li
                    className="flex flex-row lg:items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded  hover:duration-200 hover:ease-in-out"
                  >
                    <HiOutlineMail className="text-xl" /> Contact Details
                  </li>
                </NavLink>
              </div>
            </div>
          </>
        )}
        {getData === "2" && (
          <>
            <div className="lg:flex flex-col w-1/5  h-screen  hidden md:fixed left-0 top-0 bg-black1 overflow-y-scroll">
              <div className="w-full h-fit px-6 py-8 flex flex-col  bg-primary gap-4">
                <h1 className="text-2xl font-bold text-secondary m-auto capitalize">
                  {getRoleName}
                </h1>
                <div
                  className="flex justify-center w-full h-fit cursor-pointer"
                  onClick={navigateToDashboard}
                >
                  <img
                    src={weightloss_log}
                    alt="RBF"
                    className="w-full h-fit rounded-sm"
                  />
                </div>
                {/* <div className="flex flex-row w-full mt-4 p-3 justify-between bg-regal-blue rounded-lg">
                  <div>
                    <h6 className="font-bold text-xl text-gray-100">Vicky</h6>
                    <p className=" text-gray-400">Production</p>
                  </div>
                  <div className="flex justify-center items-center">
                    <TiArrowUnsorted className="w-4 h-4 text-gray-400 " />
                  </div>
                </div> */}
              </div>
              <div className="h-px w-full bg-gray-600"></div>
              <div className="h-full bg-white">
                <div className="flex flex-col justify-start px-6 gap-1.5  py-6  h-full bg-primary">
                  <NavLink
                    to="/homepage/dashboard"
                    isActive={(match, location) => {
                      // Check if the current location matches the menu's path or any of its subpaths
                      return (
                        match ||
                        location.pathname.startsWith(
                          "/homepage/dashboard" + "/"
                        ) // Assuming child routes have paths like /homepage/institutes/child
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "4px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                    >
                      <MdOutlineInventory className="text-xl" /> Orders
                    </li>
                  </NavLink>
                </div>
              </div>
            </div>
          </>
        )}

        {getData === "4" && (
          <>
            <div className="lg:flex flex-col w-1/5  h-screen  hidden md:fixed left-0 top-0 bg-black1 overflow-y-scroll">
              <div className="w-full h-fit px-6 py-8 flex flex-col  bg-primary gap-4">
                <h1 className="text-2xl font-bold text-secondary m-auto capitalize">
                  {getRoleName}
                </h1>
                <div
                  className="flex justify-center w-full h-fit rounded-lg  cursor-pointer"
                  onClick={navigateToDashboard}
                >
                  <img
                    src={weightloss_log}
                    alt="RBF"
                    className="w-full h-fit rounded-sm"
                  />
                </div>
                {/* <div className="flex flex-row w-full mt-4 p-3 justify-between bg-regal-blue rounded-lg">
                  <div>
                    <h6 className="font-bold text-xl text-gray-100">Vicky</h6>
                    <p className=" text-gray-400">Production</p>
                  </div>
                  <div className="flex justify-center items-center">
                    <TiArrowUnsorted className="w-4 h-4 text-gray-400 " />
                  </div>
                </div> */}
              </div>
              <div className="h-px w-full bg-gray-600"></div>
              <div className="h-full bg-white">
                <div className="flex flex-col justify-start px-6 gap-1.5  py-6  h-full bg-primary ">
                  <NavLink
                    to="/homepage/dashboard"
                    isActive={(match, location) => {
                      // Check if the current location matches the menu's path or any of its subpaths
                      return (
                        match ||
                        location.pathname.startsWith(
                          "/homepage/dashboard" + "/"
                        ) // Assuming child routes have paths like /homepage/institutes/child
                      );
                    }}
                    style={({ isActive }) => {
                      return isActive
                        ? {
                            backgroundColor: "#364152",
                            borderRadius: "4px",
                            color: "#F97316",
                          }
                        : {
                            color: "#94a3b4",
                          };
                    }}
                  >
                    <li
                      className="flex flex-row items-center gap-4  p-2  text-base font-bold hover:text-third
         hover:bg-gray-700 rounded-lg  hover:duration-200 hover:ease-in-out"
                    >
                      <MdOutlineInventory className="text-xl" /> Orders
                    </li>
                  </NavLink>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SideBar;
