import "./App.css";
import Login from "./components/Login";
import { Route, Routes } from "react-router-dom";
import HomePage from "./components/HomePage";
import PrivateRoutes from "./components/PrivateRoute";
import DashBoard from "./pages/DashboardComponent/DashBoard";
import OrderViewDetails from "./pages/DashboardComponent/OrderViewDetails";
import DocumentPdf from "./pages/DashboardComponent/DocumentPdf";
import DispenserLabel from "./pages/DashboardComponent/DispenserLabel";
import PrescriberNotes from "./pages/DashboardComponent/PrescriberNotes";
import Configs from "./pages/ConfigsComponent/Configs";
import ConfigsForm from "./pages/ConfigsComponent/ConfigsForm";
import Referral from "./pages/RefferalsComponent/Referral";
import ReferralForm from "./pages/RefferalsComponent/ReferralForm";
import Customers from "./pages/CustomersComponent/Customers";
import CustomersForm from "./pages/CustomersComponent/CustomersForm";
import CustomersIdProofPdf from "./pages/CustomersComponent/CustomersIdProofPdf";
import CustomersDetailPage from "./pages/CustomersComponent/CustomersDetailPage";
import Products from "./pages/MedicinesComponent/Products";
import ProductPage from "./pages/MedicinesComponent/ProductPage";
import ProductForm from "./pages/MedicinesComponent/ProductForm";
import ProductForm1 from "./pages/MedicinesComponent/ProductForm1";
import Staffs from "./pages/StaffsComponent/Staffs";
import DoctorsForm from "./pages/StaffsComponent/DoctorsForm";
import AssessmentQuestions from "./pages/AssessmentsComponent/AssessmentQuestions";
import AssessmentQuestionForm from "./pages/AssessmentsComponent/AssessmentQuestionForm";
import EmailTemplate from "./pages/EmailTemplateComponent/EmailTemplate";
import EmailTemplateForm from "./pages/EmailTemplateComponent/EmailTemplateForm";
import Roles from "./pages/RolesComponent/Roles";
import RolesForm from "./pages/RolesComponent/RolesForm";
import Setting from "./subComponents/Setting";
import { Bounce, Flip, ToastContainer, Zoom } from "react-toastify";
import FeeDetails from "./pages/FeedetailsComponent/FeeDetails";
import FeeDetailsForm from "./pages/FeedetailsComponent/FeeDetailsForm";
import ShowFeeDetails from "./pages/FeedetailsComponent/ShowFeeDetails";
import StaffDetailPage from "./pages/StaffsComponent/StaffDetailPage";
import ContactUs from "./pages/ContactUsComponent/ContactUs";

function App() {
  return (
    <div className="w-full h-screen bg-white">
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="homepage" element={<HomePage />}>
            <Route path="/homepage/dashboard" element={<DashBoard />} />
            <Route
              path="/homepage/dashboard/viewdetails/:id/:user_id"
              element={<OrderViewDetails />}
            />
            <Route
              path="/homepage/dashboard/prescribernotes/:id/:user_id"
              element={<PrescriberNotes />}
            />
            <Route
              path="/homepage/dashboard/document/:id/:user_id"
              element={<DocumentPdf />}
            />
            <Route
              path="/homepage/dashboard/dispenserlabel/:id/:user_id"
              element={<DispenserLabel />}
            />

            <Route path="/homepage/configs" element={<Configs />} />
            <Route
              path="/homepage/configs/configsform"
              element={<ConfigsForm />}
            />
            <Route
              path="/homepage/configs/configsformedit/:id"
              element={<ConfigsForm />}
            />
            <Route path="/homepage/referral" element={<Referral />} />
            <Route
              path="/homepage/referral/referralform"
              element={<ReferralForm />}
            />
            <Route
              path="/homepage/referral/referralformedit/:id"
              element={<ReferralForm />}
            />
            <Route path="/homepage/user" element={<Customers />} />
            <Route
              path="/homepage/user/userdetailpage/:id"
              element={<CustomersDetailPage />}
            />
            <Route
              path="/homepage/user/userdetailpage/customeridproof/:id"
              element={<CustomersIdProofPdf />}
            />

            <Route path="/homepage/usersform" element={<CustomersForm />} />
            <Route
              path="/homepage/usersformedit/:id"
              element={<CustomersForm />}
            />

            <Route path="/homepage/products" element={<Products />} />
            <Route
              path="/homepage/products/productspage"
              element={<ProductPage />}
            />
            <Route
              path="/homepage/products/basic_detailsedit/:id"
              element={<ProductForm />}
            />
            <Route path="/homepage/faqadd" element={<ProductForm1 />} />

            <Route path="/homepage/staffs" element={<Staffs />} />

            <Route
              path="/homepage/staffs/viewDetails/:id"
              element={<StaffDetailPage />}
            />

            <Route
              path="/homepage/staffs/formedit/:id"
              element={<DoctorsForm />}
            />

            <Route path="/homepage/staffs/addform" element={<DoctorsForm />} />

            <Route
              path="/homepage/assessmentquestions"
              element={<AssessmentQuestions />}
            />
            <Route
              path="/homepage/assessmentquestions/assessmentquestionform"
              element={<AssessmentQuestionForm />}
            />
            <Route
              path="/homepage/assessmentquestions/assessmentquestionformedit/:id"
              element={<AssessmentQuestionForm />}
            />

            <Route path="/homepage/emailtemplate" element={<EmailTemplate />} />
            <Route
              path="/homepage/emailtemplate/emailtemplateform"
              element={<EmailTemplateForm />}
            />
            <Route
              path="/homepage/emailtemplate/emailtemplateformedit/:id"
              element={<EmailTemplateForm />}
            />

            <Route path="/homepage/roles" element={<Roles />} />
            <Route path="/homepage/rolesform" element={<RolesForm />} />
            <Route path="/homepage/rolesformedit/:id" element={<RolesForm />} />

            <Route path="/homepage/setting/:id" element={<Setting />} />

            <Route path="/homepage/feedetails" element={<FeeDetails />} />

            <Route
              path="/homepage/feedetailsform"
              element={<FeeDetailsForm />}
            />
            <Route
              path="/homepage/feedetailseditform/:id"
              element={<FeeDetailsForm />}
            />
            <Route
              path="/homepage/showfeedetails"
              element={<ShowFeeDetails />}
            />
            <Route
              path="/homepage/contactus"
              element={<ContactUs />}
            />
            
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnHover={true}
        theme="light"
        transition={Flip}
      />
    </div>
  );
}

export default App;
