import React from "react";
import { DatePicker, Space } from "antd";
import { useState } from "react";
import dayjs from "dayjs";
import { useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

const { RangePicker } = DatePicker;

const apiUrl = process.env.REACT_APP_API_URL;

const imageUrl = process.env.REACT_APP_IMAGE_URL;

const StaffDetailPage = () => {
  const { id } = useParams();

  const [selectedDate, setSelectedDate] = useState([]);

  const handleDateChange = (dates) => {
    setSelectedDate(dates);
    if (dates && dates[0] && dates[1]) {
      const formattedStartDate = dayjs(dates[0]).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(dates[1]).format("YYYY-MM-DD");
      console.log(formattedStartDate + " to " + formattedEndDate);
      axios
        .get(
          `${apiUrl}/orders/${formattedStartDate}/${formattedEndDate}`,
          config
        )
        .then((res) => {
          console.log(res.data.data);
        })
        .catch((err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const navigate = useNavigate();

  const [role, setRole] = useState("");

  const [mappedUsers, setMappedUsers] = useState("");

  const [img, setImg] = useState(null);

  const goback = () => {
    navigate("/homepage/membermenu/getdoctors");
  };

  const config = {
    headers: {
      authorization: `Bearer ${localStorage.getItem("token_id")}`,
    },
  };

  const getData = () => {
    axios
      .get(`${apiUrl}/doctors/${id}`, config)
      .then((res) => {
        //console.log("res", res.data.data);
        if (res !== undefined) {
          setImg(res.data.data[0].sign_image);
          setMappedUsers(res.data.data[0]);
          if (res.data.data[0]?.role === "1") return setRole("Superadmin");
          else if (res.data.data[0]?.role === "2") return setRole("Precriber");
          else if (res.data.data[0]?.role === "4") return setRole("Dispencer");
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="flex justify-center items-center w-full py-20">
      <div className="flex flex-row justify-center items-center w-full h-[350px] gap-5">
        <div className="w-1/2 bg-white rounded drop-shadow border h-full p-4">
          <h1 className="text-gray-700 text-lg font-bold mb-2">
            Staff Details :
          </h1>
          <div className="w-full h-fit flex flex-col justify-between gap-4">
            <div className="w-full flex flex-row items-start justify-center h-fit">
              <div className="w-1/2 flex flex-col h-full gap-1">
                <span className="text-[16px] text-gray-600 font-medium capitalize">
                  First Name : {mappedUsers?.first_name}
                </span>
                <span className="text-[16px] text-gray-600 font-medium capitalize">
                  Surname : {mappedUsers?.surname}
                </span>

                <span className="text-[16px] text-gray-600 font-medium capitalize">
                  Role : {role}
                </span>
                <span className="text-[16px] text-gray-600 font-medium capitalize">
                  Fees earned : {mappedUsers?.fee_earned}
                </span>
              </div>
              <div className="w-1/2 flex flex-col h-full gap-1">
                <span className="text-[16px] text-gray-600 font-medium capitalize">
                  Registration No : {mappedUsers?.registration_no}
                </span>
                <span className="text-[16px] text-gray-600 font-medium capitalize">
                  Telephone No : {mappedUsers?.phoneno}
                </span>
                <span className="text-[16px] text-gray-600 font-medium ">
                  Email ID : {mappedUsers?.email}
                </span>
              </div>
            </div>

            <div className="w-full flex flex-col h-fit gap-1 ">
              <span className="text-[16px] text-gray-600 font-medium capitalize">
                Address Line 1 : {mappedUsers?.address_line1}
              </span>
              <span className="text-[16px] text-gray-600 font-medium capitalize">
                Address Line 2 : {mappedUsers?.address_line2}
              </span>

              <span className="text-[16px] text-gray-600 font-medium capitalize">
                City : {mappedUsers?.city}
              </span>
              <span className="text-[16px] text-gray-600 font-medium capitalize">
                Postcode : {mappedUsers?.postcode}
              </span>
            </div>
          </div>
        </div>
        <div className="w-1/2 bg-white rounded drop-shadow border h-full flex flex-col p-4 gap-2">
          <h1 className="text-gray-700 text-lg font-bold h-[10%]">
            Signature :
          </h1>
          <div className="w-full h-[90%] rounded drop-shadow border flex justify-center items-center">
            {img !== "" ? (
              <img
                src={`${imageUrl}/${img}`}
                alt="profile"
                className="w-full h-full object-contain"
              />
            ) : (
              <h1 className="text-gray-600 text-md font-semibold">
                Signature has to be upload
              </h1>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffDetailPage;
